@import './variables.scss';
@import './custom_mixins.scss';
@import './screens_mixins.scss';

body {
  .menu-item {
    @include tank-font($font-size-15px, $font-weight-normal, $font-family-regular);
  }
  .MuiPopover-paper {
    ul {
      @include no-scroll-bar;
    }
  }
  .teams-dropdown {
    li {
      @include tank-font($font-size-14px, $font-weight-medium, $font-family-medium);
    }
    .active-team {
      background-color: $table-head-grey;
    }
    .MuiMenu-paper {
      width: 240px;
      .MuiMenu-list {
        padding-top: 0;
      }
    }
    .switch-teams {
      display: block;
      padding: 10px;
      color: $primary-black;
      @include tank-font($font-size-13px, $font-weight-600, $font-family-semibold);
    }
    &.roles {
      li {
        white-space: normal;
      }
      .role-title {
        @include tank-font($font-size-14px, $font-weight-600, $font-family-semibold);
      }
      .role-desc {
        color: $color-dark-grey;
        @include tank-font($font-size-13px, $font-weight-medium, $font-family-medium);
      }

    }
  }

  .MuiSkeleton-root:not(.MuiSkeleton-circle) {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .err-msg {
    font-size: $font-size-15px !important;
    font-weight: $font-weight-600 !important;
    font-family: $font-raleway-semibold !important;
    color: $err-red !important;
    text-align: center;
    margin-top: 15px;
  }

  .err-msg-secondary {
    font-size: $font-size-15px !important;
    font-weight: $font-weight-600 !important;
    font-family: $font-family-semibold !important;
    color: $err-red !important;
    text-align: center;
    margin-top: 15px;
  }

  .primary-btn {
    padding: 8px 25px;
    border-radius: 7px;
    background-color: $primary-color;
    min-width: 170px;
    text-transform: none;
    @include tablet-and-desktop {
      min-width: 200px;
      padding: 12px 33px;
    }
    &:hover {
      background-color: $primary-color-hover;
      cursor: pointer;
    }
    span {
      @include tank-font($font-size-18px, $font-weight-600, $font-family-semibold);
      color: $color-white;
    }
    &:disabled {
      background-color: $color-grey;
    }
    &.mini {
      @include button-mini;
    }
    &.small {
      @include button-small;
    }
    &.grey-btn {
      @include grey-btn;
    }
    &.red {
      background-color: $color-red;
    }
    &.green {
      background-color: $color-green;
    }
    &.outline {
      background-color: transparent;
      transition: background-color 0.16s ease-in;
      &.green {
        border: 1px solid $color-green;
        span {
          color: $color-green;
        }
        &:hover {
          background-color: $color-green;
          span {
            color: $color-white;
          }
        }
      }
      &.red {
        border: 1px solid $color-red;
        span {
          color: $color-red;
        }
        &:hover {
          background-color: $color-red;
          span {
            color: $color-white;
          }
        }
      }
    }
    &.thin {
      padding: 8px 33px;
      span {
        @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
      }
    }
    &.google {
      background-color: $color-black;
      display: flex;
      justify-content: center;
      .MuiButton-label:before {
        content: '';
        background-image: url('../assets/images/icon-google.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 22px;
        margin-right: 7px
      }
    }
    &.full-width {
      width: 100%;
    }
  }
  .timestamp {
    display: block;
    @include tank-font($font-size-12px, $font-weight-600, $font-family-bold);
    color: $grey-dark;
  }
  .rounded-btn {
    border-radius: 100px;
  }
  .full-width-block {
    @include small-mobile-and-mobile {
      width: 100%;
      flex-basis: auto;
      max-width: 100%;
    }
  }
  .space-top {
    @include mobile-and-tablet {
      padding-top: 100px;
    }
  }
  .dropdown-menu-item {
    font-size: $font-size-14px;
    @include mobile-and-tablet {
      min-height: auto;
    }
  }
  .MuiSelect-select {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    right: 10px;
  }
  .dropdown-comp {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border: 1px solid $color-grey;
    border-radius: 6px;
    position: relative;
    .MuiInput-underline {
      &:before,
      &:after {
        border: 0 !important;
      }
    }
    .selected-option {
      font-family: $font-family-regular;
    }
    .MuiSelect-select.MuiSelect-select {
      height: 23px;
      font-size: $font-size-14px;
      display: flex;
      align-items: center;
      padding: 9px;
      padding: 7px 13px;
    }
    .selected-color {
      width: 25px;
      height: 25px;
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      border-radius: 4px;
    }
    &.colors {
      width: 100%;
      min-width: auto;
      margin-bottom: 0;
      .MuiInputBase-formControl {
        z-index: 5;
      }
    }
  }
  .color-dropdown {
    transition: none;
    background-color: transparent !important;
    padding: 5px;
    > .MuiTouchRipple-root {
      display: none;
    }
  }
  .dropdown-menu-item {
    font-size: $font-size-14px;
    font-family: $font-family-regular;
  }
  .MuiList-root.MuiMenu-list[aria-labelledby="select-dropdown"] {
    max-height: 190px;
    overflow-y: scroll;
  }
  .tank-web .stepper-content {
    @include small-mobile-and-mobile {
      padding: 37px 24px;
      min-height: auto
    }
  }
  .tank-web .user-onboard {
    @include small-mobile-and-mobile {
      width: 100%;
    }
  }
  .section-info {
    margin-bottom: 25px;
    text-align: center;
    @include desktop-and-large-screen {
      margin-bottom: 45px;
    }
    .section-title.center {
      margin-bottom: 35px;
      @include desktop-and-large-screen {
        margin-bottom: 28px;
      }
    }
    .subheading {
      margin: 0 auto;
      @include desktop-and-large-screen {
        max-width: 650px;
      }
    }
  }
  .page-spinner {
    position: relative;
    .tank-spinner {
      top: 50%;
      right: 50%;
    }
  }
}

.screen-max-width {
  @include screen-max-width;
  // &.dashboard-gutters {
  //   padding: 0 $desktop-gutter-space-dashboard;
  // }
}

.page-top {
  @include mobile-and-tablet {
    padding-top: $page-layout-margin-top-mobile;
  }
  padding-top: $page-layout-margin-top-desktop;
}

.full-page-height {
  min-height: 100vh;
}

.dashboard-vert-gutters {
  @include mobile-and-tablet {
    margin: $dashboard-page-margin-top-mobile 0 $dashboard-page-margin-bottom-mobile;
  }
  margin: $dashboard-page-margin-top-desktop 0 $dashboard-page-margin-bottom-desktop;
}

.adjusted-container {
  @include desktop-and-large-screen {
    width: calc(100% - #{$drawerWidth});
    position: absolute;
    top: 0;
    right: 0;
  }
}

.form-err-msg {
  margin-bottom: 10px;
  color: $color-red;
}

.tank-modal {
  outline: 0;
  max-width: 1000px;
  border: 0 !important;
  border-radius: 7px;
}

.page-loader-pos .tank-loader {
  position: absolute;
  top: 50%;
  left: 56%;
  transform: translate(-56%, -50%);
  height: 53px;
  width: 262px;
}

.no-data {
  text-align: center;
  @include tank-font($font-size-30px, $font-weight-medium, $font-family-semibold);
}

.table-card {
  $border-radius: 7px;
  border: 1px solid #E4E4E4;
  border-radius: $border-radius;
  background-color: #ffffff;
  margin-bottom: 4.375rem;
  padding: 10px 30px;
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      padding: 0.75rem 1.5rem;
      color: #9A9A9A;
      text-align: left;
      @include tank-font($font-size-12px, $font-weight-bold, $font-family-bold);
      text-transform: uppercase;
      &:first-child {
        border-top-left-radius: $border-radius;
      }
      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
    tr {
      border-bottom: 1px solid #e9ecef;
    }
    tbody {
      tr {
        &:last-child {
          border: 0;
        }
      }
      .timestamp {
        @include tank-font($font-size-12px, $font-weight-normal, $font-family-regular);
      }
    }
    td,
    td a,
    td p,
    td span {
      @include tank-font($font-size-15px, $font-weight-medium, $font-family-medium);
    }
    td {
      padding: 1rem 1.5rem;
      color: $primary-black;
      &.text-right {
        text-align: right;
      }
      .timestamp {
        margin-top: 3px;
      }
      .primary-btn {
        @include button-mini;
        border: 1px solid #D0D0D0;
        background-color: white;
        border-radius: 2px;
        .MuiButton-label {
          color: $color-black;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}

.btn-outline {
  button {
    width: 100%;
    padding: 5px 40px;
    text-transform: none;
    background-color: $color-beige;
    border-radius: 0;
    color: $primary-black;
    border-radius: 6px;
    box-shadow: none;
    border: 1.3px solid $primary-black;
    span {
      @include tank-font($font-size-16px, $font-weight-600, $font-family-semibold);
    }
  }
}

.modal-comp {
  background-color: rgba(0,0,0,0.2);
  z-index: 1200;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  .modal-title-container {
    border-bottom: 1px solid $color-grey;
    padding-bottom: 4px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    .close-modal-icon {
      cursor: pointer;
    }
  }
  .modal-title {
    margin: 0;
    @include tank-font($font-size-16px, $font-weight-medium, $font-family-medium);
    @include desktop-and-large-screen {
      font-size: $font-size-18px;
    }
  }

  /* Modal content */
  .modal-content {
    background-color: $color-white;
    box-shadow: 0 1px #0000000d, 0 0 20px #00000026;
    width: 53%;
    max-width: 950px;
    height: auto;
    margin: auto;
    padding: 20px 22px 25px;
    border-radius: 4px;
    border: 0;
    @include desktop-and-large-screen {
      padding: 30px 33px;
    }
    .dd-group {
      @include mobile-and-tablet {
        align-items: baseline;
      }
    }
    .tox-statusbar {
      display: none !important;
    }
    @include tablet {
      width: 470px;
    }
    &.feedback-modal {
      min-height: auto;
      max-width: 450px;
      .feedback-msg-content {
        margin-bottom: 24px;
        p {
          margin: 0;
          @include tank-font($font-size-18px, $font-weight-medium, $font-family-medium);
        }
      }
    }
  }
  .modal-flex {
    display: flex;
    height: 100%; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .modal-info {
    background-color: #888;
    border: 1px solid #888;
  }

  /* Close button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  &.full-page-editor {
    background-color: $color-white;
    position: fixed;
    width: calc(100% - 270px);
    height: 100%;
    top: 28px;
    right: 0;
    left: auto;
    .container-wrapper {
      max-width: 870px;
      padding: 0 35px;
      margin: 0 auto;
      &:first-child {
        padding-top: 60px;
      }
    }
    .modal-content {
      width: 100%;
      // max-width: 870px;
      height: 100%;
      // margin: 0 auto;
      border: 0;
      // padding: 60px 35px 0;
      overflow: scroll;
      box-shadow: none;
      border-radius: 0;
      padding-top: 0;
      textarea {
        font-family: $font-family-bold;
      }
      .text-field {
        border: 0;
        padding: 0;
        font-size: 38px;
        height: 58px;
        font-weight: bold;
        color: $primary-black;
        &::-webkit-input-placeholder {
          font-size: 38px;
          color: $divided-grey-secondary;
        }
        &::-moz-placeholder {
          font-size: 38px;
          color: $divided-grey-secondary;
        }
        &:-ms-input-placeholder {
          font-size: 38px;
          color: $divided-grey-secondary;
        }
        &:-moz-placeholder {
          font-size: 38px;
          color: $divided-grey-secondary;
        }
      }
    }
    .btn-group button {
      padding: 2px 9px;
      span {
        font-size: 12px;
      }
    }
    .TankEditorTool {
      border: 0;
      border-radius: 0;
      .ce-block__content,
      .ce-toolbar__content {
        max-width: 796px;
      }
      .ce-block {
        b {
          font-family: $font-family-bold;
        }
        .inline-code {
          font-size: 1rem;
        }
      }
    }
  }
}

.query-details {
  h3.query-title {
    margin: 0;
    color: $primary-black;
    @include tank-font($font-size-38px, $font-weight-bold, $font-family-bold);
  }
  .collection-title {
    @include tank-font($font-size-12px, $font-weight-bold, $font-family-bold);
    color: $color-dark-grey;
  }
  .collection-name {
    @include tank-font($font-size-14px, $font-weight-bold, $font-family-bold);
    color: $primary-black;
    padding-left: 5px;
  }
}

.input-label {
  display: inline-block;
}

.mandate-mark {
  color: $color-red;
  margin-left: 4px;
}

.hidden {
  display: none;
}

.btn-navigate {
  width: 100%;
  padding: 10px 40px;
  background-color: #5171ff !important;
  border-radius: 6px;
  border: 1.3px solid #3f434a;
  color: #ffffff;
  @include tank-font($font-size-15px, $font-weight-600, $font-family-semibold);
  line-height: normal;
  text-align: center;
}

.goto-tank {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  span {
    @include tank-font($font-size-15px, $font-weight-medium, $font-raleway-medium);
    color: $grey-dark;
  }
  .logo-container {
    margin: 0 20px !important;
    display: inline-block;
    height: 40px;
  }
}

.mobile-nav-icon {
  width: 38px;
  height: 28px;
  position: absolute;
  z-index: 10;
  right: $mobile-gutter-space;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  @include desktop-and-large-screen {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $primary-black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 11px;
    }
    &:nth-child(4) {
      top: 22px;
    }
  }
}

.alt-grey-section {
  background: linear-gradient(180.19deg, #FCFCFC 58.37%, #F1F1F1 99.83%, rgba(250, 250, 250, 0.0208333) 99.83%);
}
.alt-white-section {
  background-color: $color-white;
}

.solid-grey-bg {
  background-color: $bg-grey;
  // background: transparent;
}

.alt-grey-section,
.alt-white-section,
.solid-grey-bg {
  padding: 55px 0 135px;
  @include desktop-and-large-screen {
    padding: 70px 0 135px;
  }
}

.section-title.center {
  text-align: center;
  margin: 0 auto 55px;
  @include tank-font($font-size-40px, $font-weight-bold, $font-raleway-bold);
  @include desktop-and-large-screen {
    max-width: 700px;
    font-size: $font-size-45px;
    margin-bottom: 45px;
  }
}

.entry-navigate {
  text-align: center;
  margin-bottom: 8px;
  a {
    color: $primary-color;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.or {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $color-grey;
  line-height: 0.02em !important;
  margin: 10px 0 20px !important;
  span {
    background: $bg-grey;
    padding: 0 10px;
    color: $grey-dark;
    @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
  }
}

.shimmers {
  span:first-child {
    margin-bottom: 12px;
  }
}
