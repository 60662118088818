.loading-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-page {
    position: relative;
  }
  .tank-spinner {
    top: 0;
    right: -32px;
  }
}
