@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.user-onboard {
  margin: 0 auto;
  width: 450px;
  box-shadow: $box-shadow;
  border-radius: 6px;
  .stepper-content {
    padding: 40px;
    background-color: $color-white;
    h3 {
      margin-bottom: 25px;
      text-align: center;
      @include tank-font($font-size-22px, $font-weight-bold, $font-family-bold);
    }
    .onboard-info-block {
      margin-bottom: 10px;
    }
  }
}
.stepper-nav-btns {
  display: flex;
  justify-content: flex-end;
  &.prev-btn {
    justify-content: space-between;
  }
  button {
    padding: 0;
    margin: 0;
    background-color: transparent !important;
    color: $color-black;
    box-shadow: none !important;
    min-width: auto;
  }
  a span {
    color: $color-white;
  }
  .primary-btn {
    @include button-small;
    max-width: 155px;
  }
  .MuiSkeleton-root {
    margin-top: 20px;
  }
}
