@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.widget-item {
  margin-bottom: 40px;
  &:last-child {
    margin: 0;
  }
}
.widget {
  $paddingVal: 1.25rem 1.5rem;
  background-color: $color-white;
  .entry {
    position: relative;
    padding: $paddingVal;
    padding: $paddingVal 1.25rem 2.1875rem;
    border-bottom: 1px solid $border-color-light;
    &:before {
      content: '';
      position: absolute;
      border: 1.3px solid;
      border-color: $primary-color;
      background: $primary-color;
      left: 1.5rem;
      height: calc(100% - (1.25rem * 2));
    }
    &:last-child {
      border: 0;
    }
  }
  p,
  span {
    margin: 0;
    color: $primary-black;
    @include tank-font($font-size-14px, $font-weight-600, $font-family-medium);
  }
  .widget-icons {
    display: flex;
    align-items: center;
  }
  .widget-close-icon {
    margin-left: 20px;
    border-radius: 50px;
    border: 2px solid transparent;
    transition: all 0.1s ease-in-out;
    &:hover {
      cursor: pointer;
      border-color: $color-text-grey;
      background-color: $color-text-grey;
      color: $color-white;
    }
  }
  // box-shadow: $box-shadow-secondary;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 12px;
  .widget-title {
    border-bottom: 1px solid $border-color-light;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .widget-icon {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.4375rem;
      border: 1px solid black;
      border-radius: 6.25rem;
      img {
        @include image-fit;
        object-fit: contain;
      }
    }
    p {
      @include tank-font($font-size-18px, $font-weight-bold, $font-family-bold);
    }
  }
  .widget-content {
    height: 14.5625rem;
    overflow-y: scroll;
    position: relative;
    @include no-scroll-bar;
    .widget-entry {
      position: relative;
      padding: $paddingVal 1.25rem 2.1875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color-light;
      &:last-child {
        border: 0;
      }
      .task-type {
        position: absolute;
        left: 1.5rem;
        border: 1.3px solid;
        height: calc(100% - (1.25rem * 2));
        &.validate {
          border-color: $color-green;
          background: $color-green;
        }
        &.mention {
          border-color: $color-red;
          background: $color-red;
        }
        &.space-user {
          border-color: $brand-color-dark;
          background: $brand-color-dark;
        }
      }
      .name-container {
        .name {
          display: flex;
          align-items: center;
          .admin-user-indicator {
            margin-left: 6px;
          }
          .member-origin {
            display: inline-flex;
            width: 14px;
            height: 14px;
            margin-left: 6px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .member-email {
          font-size: $font-size-12px;
          color: $grey-dark;
        }
      }
    }
    .task-item {
      position: relative;
      button {
        margin-right: 0.625rem;
        &.primary-btn.preview {
          border: 1px solid $primary-color;
        }
        &:last-child {
          margin: 0;
        }
      }
      .timestamp {
        @include tank-font($font-size-12px, $font-weight-600, $font-family-bold);
        color: $grey-dark;
      }
    }
    .task-title {
      margin-right: 0.9375rem;
    }
    .user {
      @include tank-font($font-size-14px, $font-weight-bold, $font-family-bold);
    }
    .no-tasks-asset {
      img {
        @include image-fit;
      }
    }
    &.no-tasks-available {
      height: auto;
      overflow: auto;
      min-height: 330px;
    }
    .no-tasks-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      .no-tasks-asset {
        width: 245px;
      }
    }
  }
}
