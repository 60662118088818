@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.user-sign-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  @include mobile-and-tablet {
    margin-bottom: 50px;
  }
  .sign-up-wrapper {
    @include bordered-box;
    padding: 0;
    @include mobile-and-tablet {
      margin-top: 55px;
      padding: 10px;
    }
    @include desktop-and-large-screen {
      border: 0;
    }
    h2 {
      text-align: center;
      margin: 0 0 8px 0;
      @include tank-font($font-size-35px, $font-weight-bold, $font-raleway-bold);
      color: $color-black;
    }
    p {
      color: $grey-dark;
      margin: 0;
      line-height: 1.5;
      @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
    }
    p.Mui-error {
      color: $err-red;
      @include tank-font($font-size-12px, $font-weight-medium, $font-raleway-medium);
    }
    .form-action-btns {
      margin-bottom: 25px;
      @include mobile-and-tablet {
        margin-bottom: 35px;
      }
    }
    .primary-btn {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
    }
    .sign-up-item-form {
      @include mobile-and-tablet {
        width: 100%;
        max-width: none;
        flex-basis: auto;
      }
      @include desktop-and-large-screen {
        padding: 48px 70px;
        border: 1px solid #F1F0F0;
        border-radius: 6px 0 0 6px;
      }
    }
    .form-field {
      width: 100%;
      margin-bottom: 15px;
      @include desktop-and-large-screen {
        margin-bottom: 12px;
      }
      &.password {
        margin-bottom: 12px;
      }
    }
    label {
      @include tank-font($font-size-15px, $font-weight-600, $font-raleway-medium);
      color: $grey-dark;
    }
    label[class*="error"] {
      color: $err-red;
    }
    input {
      @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
      color: $color-black;
      &:disabled {
        background-color: $color-light-grey;
        padding-left: 8px;
        color: $color-dark-grey;
      }
    }
    .password-helper {
      font-size: $font-size-12px;
      margin-bottom: 30px;
    }
    .sign-up-item-desc {
      background: linear-gradient(180deg, #F5F7FF 0%, #D2DBFF 100%);
      padding: 20px 0 20px 30px;
      border-radius: 0 6px 6px 0;
      @include mobile-and-tablet {
        display: none;
      }
      p {
        text-align: center;
        color: $color-black;
      }
      .brand-logo {
        height: 18px;
        margin: 0 7px;
      }
      .sign-up-asset {
        height: 400px;
        text-align: right;
        margin-bottom: 23px;
        img {
          @include image-fit;
          width: auto;
          filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.25));
          @media (max-width: 1050px) {
            width: 100%;
          }
        }
      }
      .bubble-asset {
        text-align: right;
        padding-right: 18px;
        height: 55px;
        img {
          @include image-fit;
          width: auto;
        }
      }
      .try-tank-text {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
      .desc-text {
        margin: 0 24px 28px;
        p {
          font-family: $font-raleway-medium;
          font-weight: $font-weight-medium;
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .create-tank-acnt-terms {
    max-width: $bordered-box-width;
    margin: 47px auto 0;
    p {
      color: $grey-dark;
      margin: 0;
      line-height: 1.5;
      @include tank-font($font-size-14px, $font-weight-600, $font-raleway-semibold);
    }
  }
}

.verification-modal {
  .modal-content {
    max-width: 500px;
  }
}

