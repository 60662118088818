@import '../../../../scss/variables';
@import '../../../../scss/custom_mixins.scss';

.btn-group {
  padding: 0;
  margin: 0;
  display: inline-flex;
  position: relative;
  .tank-spinner {
    top: 11px;
    right: -30px;
  }
  button {
    padding: 6px 12px;
    margin-right: 10px;
    transition: background-color color 500ms ease-in;
    box-shadow: none;
    &:last-of-type {
      margin-right: 0
    }
    span {
      @include tank-font($font-size-14px, $font-weight-bold, $font-family-semibold);
    }
    &.primary-btn-modal {
      background-color: transparent;
      border: 1.3px solid $color-red;
      color: $color-red;
      &:hover {
        background-color: $color-red;
        color: $color-white;
      }
    }
    &.secondary-btn-modal {
      border: 1.3px solid transparent;
      &:not(:disabled) {
        background-color: transparent;
        border-color: $primary-black;
        color: $primary-black;
        &:hover {
          background-color: $primary-black;
          color: $color-white;
        }
      }
    }
    &.draft-btn-modal {
      border: 1.3px solid transparent;
      &:not(:disabled) {
        // background-color: $primary-black;
        // border-color: $primary-black;
        // color: $color-white;
        // transition: background-color color 500ms ease-in;
        // &:hover {
        //   background-color: $primary-black;
        //   color: $color-white;
        // }
      }
    }
  }
}
