@import "../../scss/variables";
@import "../../scss/screens_mixins.scss";
@import "../../scss/custom_mixins.scss";

$spacingVal: 55px;

.features-page {
  letter-spacing: normal;
  .inner-section {
    @include mobile-and-tablet {
      .MuiGrid-item {
        padding-left: 0;
        padding-right: 0;
      }
      .shortcuts-info {
        margin: 0;
        width: 100%;
      }
    }
  }
  &.page-top {
    @include mobile-and-tablet {
      padding-top: 110px;
    }
  }
  .MuiGrid-item.pd-right {
    @include desktop-and-large-screen {
      padding-right: $spacingVal;
    }
  }
  .MuiGrid-item.pd-left {
    @include desktop-and-large-screen {
      padding-left: $spacingVal;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .inner-section,
  .features-container,
  .slack-features {
    position: relative;
  }
  .section-bg-img {
    position: absolute;
    width: 100%;
    top: 0;
    zoom: unset;
    object-fit: unset;
    z-index: -1;
  }
  .features-container {
    display: flex;
    justify-content: center;
    @include mobile-and-tablet {
      margin-bottom: 47px;
    }
  }
  .shapes-img {
    position: absolute;
    z-index: 1;
    top: 17px;
    height: 595px;
    width: auto;
    &.cyan-shape {
      right: 0;
      z-index: -1;
      top: -254px;
      @include mobile-and-tablet {
        height: 407px;
      }
    }
    &.pink-shape {
      z-index: -1;
      top: 120px;
      left: 0;
      @include mobile-and-tablet {
        top: 14px;
      }
    }
  }
  .features-info {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    @include mobile-and-tablet {
      margin: 0;
    }
  }
  .features-sections {
    position: relative;
    z-index: 2;
    .feature-img {
      object-fit: contain;
    }
  }
  .page-heading {
    margin-bottom: 43px;
    .heading {
      font-size: 2.5rem;
      font-family: $font-family-dm-sans-800;
      margin: 0;
      @include mobile-and-tablet {
        line-height: 42px;
        margin-bottom: 16px;
        font-size: $font-size-36px;
      }
    }
    .desc {
      font-size: 1.625rem;
      font-family: $font-family-dm-sans-normal;
      @include mobile-and-tablet {
        font-size: $font-size-20px;
      }
    }
  }

  .section-heading {
    position: relative;
    @include tank-font($font-size-30px, $font-weight-bold, $font-family-dm-sans-700);
    @include mobile-and-tablet {
      font-size: $font-size-22px;
      line-height: 28px;
    }
    .section-title-icon {
      width: 53px;
      position: absolute;
      top: -32px;
      @include mobile-and-tablet {
        width: 37px;
        top: -26px;
      }
      &.left {
        left: -29px;
        @include mobile-and-tablet {
          left: 0;
        }
      }
      &.right {
        right: 0;
        @include mobile-and-tablet {
          left: 0;
        }
        top: -43px;
      }
      &.exp {
        z-index: -1;
        top: -26px;
        @include mobile-and-tablet {
          top: -22px;
        }
      }
      &.mention {
        right: 33px;
      }
      &.onboarding {
        top: -50px;
        @include mobile-and-tablet {
          top: -38px;
        }
      }
    }
  }
  .section-desc {
    @include tank-font($font-size-20px, $font-weight-normal, $font-family-dm-sans-normal);
    @include mobile-and-tablet {
      font-size: $font-size-18px;
    }
  }
  .slack-features {
    padding-top: 276px;
    margin-bottom: 220px;
    @include mobile-and-tablet {
      padding-top: 120px;
      margin-bottom: 100px;
    }
    .slack-wrapper {
      @include mobile-and-tablet {
        margin-bottom: 12px;
      }
      img {
        width: 180px;
        @include mobile-and-tablet {
          width: 128px;
        }
      }
    }
    .slack-features-container {
      margin-bottom: 50px;
      .section-heading {
        font-size: $font-size-30px;
        @include mobile-and-tablet {
          font-size: $font-size-22px;
        }
      }
      .section-desc {
        margin: 0;
      }
    }
    .shortcuts-info {
      display: flex;
      align-items: center;
      .mobile-only {
        display: none;
        @include mobile-and-tablet {
          display: block;
        }
      }
      .desktop-only {
        display: none;
        @include desktop-and-large-screen {
          display: block;
        }
      }
    }
    .shortcuts-info-container {
      &.global-shortcut {
        margin-bottom: 73px;
        @include mobile-and-tablet {
          margin-bottom: 46px;
        }
      }
      .section-heading {
        font-size: $font-size-22px;
        margin: 0;
      }
      .section-desc {
        font-size: $font-size-18px;
        margin: 0;
        span {
          background-color: $color-light-grey;
          padding: 0 4px;
        }
      }
    }
    .shortcuts-grid {
      .section-desc {
        max-width: 475px;
      }
    }
  }
}
