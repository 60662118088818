@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.setting-up {
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    text-align: center;
    @include tank-font($font-size-28px, $font-weight-bold, $font-family-bold);
  }
  .user-onboard {
    width: 500px;
  }
  .tank-spinner {
    top: 44%;
    right: 24px;
  }
}
