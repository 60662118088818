@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.new-ques-modal {
  @include question-modal;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0.4375rem 0.8125rem;
  }
  .no-collec-found .text-field {
    background-color: $color-light-grey;
    pointer-events: none;
  }
  .collection-and-guru {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .collection-select,
    .search-dropdown {
      width: 100%;
    }
    .dropdown-comp,
    .search-dropdown {
      margin: 0;
    }
    .collection-select {
      padding-right: 15px;
    }
    .search-dropdown {
      padding-left: 15px;
    }
  }
}
