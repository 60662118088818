@import "../../scss/variables";
@import "../../scss/screens_mixins.scss";
@import "../../scss/custom_mixins.scss";

$dimensionLarge: 40vw;
$dimensionsMax: 37.5rem;
$dimensionSmall: 31.25rem;
$dimensionLargeMaxWidth: $dimensionsMax;
$dimensionLargeMaxHeight: $dimensionsMax;

$rotationAmount: 359deg;
$assetDimensions: 5.625rem;

@keyframes asset-wrapper-rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate($rotationAmount);
    transform: rotate($rotationAmount);
  }
}

@keyframes asset-rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-$rotationAmount);
    transform: rotate(-$rotationAmount);
  }
}

@mixin container-dimensions {
  width: $dimensionLarge;
  height: $dimensionLarge;
  max-height: $dimensionLargeMaxHeight;
  max-width: $dimensionLargeMaxWidth;
  min-height: $dimensionSmall;
  min-width: $dimensionSmall;
}

.tank-landing {
  width: 100%;
  background-color: $color-white;
  overflow: hidden;
  padding-top: 100px;
  .section-title.center {
    text-align: center;
    margin: 0 auto 35px;
    @include small-mobile-and-mobile {
      font-size: $font-size-28px;
      line-height: 38px;
    }
    @include tank-font($font-size-40px, $font-weight-bold, $font-raleway-bold);
    @include desktop-and-large-screen {
      max-width: 700px;
      font-size: $font-size-45px;
      margin-bottom: 45px;
    }
  }
  .section-asset.shadow {
    img {
      filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.25));
    }
  }
  .learn-more-btn {
    margin-top: 40px;
  }
  .btn-navigate {
    border: 0;
  }
  @include desktop-and-large-screen {
    padding-top: 210px;
  }
  .intro-section {
    margin-bottom: 90px;
    @include desktop-and-large-screen {
      margin-bottom: 315px;
      position: relative;
    }
    .intro-info {
      @include mobile-and-tablet {
        max-width: 100%;
        flex-basis: auto;
        width: 100%;
        padding: 16px 0;
      }
      @include desktop-and-large-screen {
        padding-left: 0;
        z-index: 2;
      }
    }
    .intro-container {
      width: auto;
      margin: 0;
    }
    .typer-block {
      @include mobile-and-tablet {
        text-align: center;
      }
    }
    .get-started-container {
      .get-started-btn {
        border-radius: 6px;
      }
      .get-started-btn {
        outline: none;
        padding: 14px 22px;
        background-color: $primary-color;
        color: $color-white;
        border: 0;
        @include tank-font($font-size-18px, $font-weight-medium, $font-raleway-bold);
        transition: background-color 0.16s ease-in;
        cursor: pointer;
        &:hover {
          background-color: $primary-color-hover;
        }
        &.disabled {
          background-color: #7b93ff;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
    .section-title {
      margin: 0 auto 22px;
      z-index: 2;
      text-align: left;
      line-height: 1.1;
      @include tank-font($font-size-55px, $font-weight-bold, $font-raleway-bold);
      @include tablet-and-desktop {
        max-width: 50rem;
      }
      @include max-width-and-above {
        font-size: $font-size-80px;
      }
    }
    .subheading.intro {
      margin-bottom: 18px;
      @include tank-font($font-size-16px, $font-weight-600, $font-raleway-semibold);
      line-height: 30px;
      color: $color-dark-grey;
      width: 95%;
      @include tablet-and-desktop {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 35px;
      }
      &.primary {
        margin-bottom: 30px;
      }
      &:not(.primary) {
        margin-bottom: 50px;
      }
    }
    .assets-container {
      position: relative;
      @include mobile-and-tablet {
        display: none;
      }
      .feature-wheel-asset {
        position: absolute;
        max-width: 275px;
        min-width: 200px;
        min-height: 200px;
        max-height: 275px;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          @include image-fit;
          object-fit: contain
        }
      }
    }
    .asset-transform {
      @include container-dimensions;
      position: absolute;
      display: flex;
      overflow: visible;
      width: 100%;
      min-height: $dimensionLarge;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-items: center;
      // margin-bottom: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .asset-transform-inner {
        @include container-dimensions;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        -webkit-animation: asset-wrapper-rotation 40s infinite linear;
      }
      .asset-wrap {
        position: absolute;
        width: $assetDimensions;
        height: $assetDimensions;
        padding: 23px;
        background-color: $color-white;
        border-radius: 100px;
        box-shadow: $box-shadow-secondary;
        -webkit-animation: asset-rotation 40s infinite linear;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &.one {
          top: 0;
          left: auto;
          right: auto;
          bottom: auto;
        }
        &.two {
          left: auto;
          top: 13%;
          right: 13%;
          bottom: auto;
        }
        &.three {
          left: auto;
          top: auto;
          right: 0;
          bottom: auto;
        }
        &.four {
          left: auto;
          top: auto;
          right: 13%;
          bottom: 13%;
        }
        &.five {
          left: auto;
          top: auto;
          right: auto;
          bottom: 0;
        }
        &.six {
          left: 13%;
          top: auto;
          right: auto;
          bottom: 13%;
        }
        &.seven {
          left: 0;
          top: auto;
          right: auto;
          bottom: auto;
        }
        &.eight {
          left: 13%;
          top: 13%;
          right: auto;
          bottom: auto;
        }
      }
    }
    .beige-shape {
      position: absolute;
      z-index: 1;
      top: -93px;
      height: 900px;
      @include mobile-and-tablet {
        display: none;
      }
    }
  }
  .features-section {
    position: relative;
    @include desktop-and-large-screen {
      margin-bottom: 150px;
    }
    .features-blocks-wrapper {
      width: 100%;
      margin: 0;
      justify-content: center;
      @include desktop-and-large-screen {
        display: flex;
        justify-content: center;
      }
    }
    @include tablet {
      margin-bottom: 170px;
    }
    .MuiGrid-container,
    .feat-img {
      height: 100%;
    }
    .feat-img {
      height: 220px;
      min-width: 205px;
    }
    .mobile-show {
      @include desktop-and-large-screen {
        display: none;
      }
    }
    .desktop-show {
      @include mobile-and-tablet {
        display: none;
      }
    }
    .feat-grid-item {
      @include small-mobile-and-mobile {
        padding: 0;
      }
      @include tablet {
        padding: 30px 40px;
      }
    }
  }
  .clarity,
  .slack-feat,
  .spaces-feat,
  .collec-feat {
    img {
      @include image-fit;
    }
  }
  .remote-jobs-easy {
    img {
      @include image-fit;
    }
    .mobile {
      display: block;
      @include desktop-and-large-screen {
        display: none;
      }
    }
    .desktop {
      display: block;
      @include mobile-and-tablet {
        display: none;
      }
    }
  }
  section .section-asset {
    text-align: center;
    @include mobile-and-tablet {
      height: 275px;
      width: 100%;
    }
    @include desktop-and-large-screen {
      padding: 0;
    }
    img {
      width: 100%;
      object-fit: contain;
      @include desktop-and-large-screen {
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
  .collec-feat {
    position: relative;
    @include small-mobile-and-mobile {
      padding: 115px 0 88px;
    }
    @include desktop-and-large-screen {
      padding: 225px 0;
    }
    img {
      @include desktop-and-large-screen {
        zoom: 2;
      }
    }
    .bg-ele {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
      background: #FDFDFD;
    }
    .shapes-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      zoom: unset;
      object-fit: unset;
    }
    .screen-max-width {
      position: relative;
    }
  }
  .slack-feat {
    .slick-slider,
    .slick-slider * {
      height: 100%;
    }
    .slick-dots {
      height: auto;
      bottom: -23px;
    }
    .slick-slider .slick-slide img {
      @include desktop-and-large-screen {
        height: 760px;
        max-width: none;
      }
    }
    .btn-navigate {
      display: flex;
      margin: 0 auto;
      width: 200px;
      padding: 0;
      background-color: transparent !important;
      box-shadow: $box-shadow-secondary;
    }
  }
  .clarity {
    background: linear-gradient(180deg, #FDFDFD 66.79%, #F2F1F1 100%) !important;
    .clarity-asset {
      @include tablet-and-desktop {
        height: 409px;
        img {
          zoom: 2;
        }
      }
    }
  }
  .pricing-plans {
    background-color: $grey-shade;
    a {
      @include tank-font($font-size-18px, $font-weight-medium, $font-raleway-semibold);
      color: $color-white;
      text-align: center;
      min-width: auto;
      padding: 10px 26px;
    }
    .start-free {
      background-color: $color-black;
      color: $color-white;
      margin-right: 25px;
      @include small-mobile-and-mobile {
        margin: 0 0 18px;
      }
    }
    .detail-plan {
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-grey;
    }
    .btns-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      @include small-mobile-and-mobile {
        flex-direction: column;
        margin-bottom: 60px;
      }
    }
    .plans {
      display: flex;
      justify-content: center;
      margin: 0;
      width: 100%;
    }
    .plan-type {
      @include small-mobile-and-mobile {
        padding: 0 0 33px;
        max-width: 100%;
        flex-basis: 100%;
        &:last-child {
          padding-bottom: 0;
        }
      }
      @include tablet-and-desktop {
        // max-width: 20%;
        // flex-basis: 20%;
      }
      &:nth-child(3) {
        .plan-info-container {
          background-color: $green-shade;
        }
      }
    }
    .plan-info-container {
      text-align: center;
      padding: 28px;
      height: 100%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $color-white;
    }
    .type,
    .amount,
    .plan-feature {
      color: $color-black;
    }
    .type {
      margin-bottom: 30px;
      @include tank-font($font-size-20px, $font-weight-medium, $font-raleway-semibold);
    }
    .amount {
      margin-bottom: 5px;
      @include tank-font($font-size-50px, $font-weight-bold, $font-raleway-bold);
    }
    .cycle {
      margin-bottom: 28px;
      @include tank-font($font-size-12px, $font-weight-medium, $font-raleway-semibold);
      color: $grey-dark;
    }
    .plan-feature {
      margin: 0;
      @include tank-font($font-size-12px, $font-weight-medium, $font-raleway-semibold);
    }
  }
}
