@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.back-btn {
  display: inline-block;
  .back-btn-item {
    border: 0;
    background: transparent;
    color: $brand-color;
    display: flex;
    align-items: center;
    @include tank-font($font-size-18px, $font-weight-medium, $font-family-medium);
    &:hover {
      cursor: pointer;
    }
  }
}
