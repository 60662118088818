@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';
@import '../../scss/screens_mixins.scss';

.feature-card {
  // background-color: $color-white;
  // height: 100%;
  // border-radius: 8px;
  // @include hover-move;
  // @include box-color-gradient;
  img {
    @include image-fit;
  }
  .feat-content {
    padding: 40px 32px;
    text-align: center;
    border-radius: 8px;
    @include desktop-and-large-screen {
      padding: 42px 25px 0;
    }
    button {
      height: auto;
      border-radius: 25px;
      padding: 5px 25px;
      min-width: auto;
      background-color: transparent;
      transition: background-color 0.15s ease-in-out;
      .MuiButton-label {
        font-family: $font-family-regular;
        text-transform: capitalize;
      }
      &:hover {
        background-color: $primary-black;
        .MuiButton-label {
          color: $color-white;
        }
      }
    }
  }
  .feat-title {
    @include tank-font($font-size-24px, $font-weight-medium, $font-raleway-semibold);
    line-height: 32px;
    margin-bottom: 15px;
    @include desktop-and-large-screen {
      font-size: $font-size-28px;
    }
  }
  .feature-img {
    height: 220px;
    margin-bottom: 30px;
    img {
      object-fit: contain;
    }
  }
  .feat-desc {
    line-height: 26px;
    margin: 0;
    color: $color-dark-grey;
    @include tank-font($font-size-14px, $font-weight-600, $font-raleway-medium);
    @include desktop-and-large-screen {
      font-size: $font-size-18px;
      line-height: 29px;
    }
  }
}
