@import './variables.scss';

/************* Media Query mixin *************/

@mixin small-mobile {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin small-mobile-and-mobile {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media (max-width: $screen-md-max) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}

@mixin tablet-and-desktop {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin desktop-and-large-screen {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin max-width-and-above {
  @media (min-width: $screen-max-width) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: $screen-lg-min) and (max-width: $screen-tab-landscape-max) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) and (orientation: landscape) {
    @content;
  }
}

@mixin ie-browser {
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin edge-browser {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin mozilla {
  @supports (-moz-appearance:none) {
    @content;
  }
}

@mixin ios-devices {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}
