@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.user-sign-in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  .screen-max-width {
    width: 100%;
  }

  /* OLD LOGIN */
  .logo-container {
    text-align: center;
    margin-bottom: 25px;
    .brand-logo {
      height: 40px;
    }
  }
  .stepper-content {
    min-height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text-field {
      box-sizing: border-box;
    }
  }
  .user-onboard {
    margin-bottom: 20px;
  }
  .sign-in-title {
    text-align: center;
    @include tank-font($font-size-24px, $font-weight-bold, $font-family-bold);
    margin: 0 0 35px;
    @include small-mobile-and-mobile {
      margin-bottom: 25px;
    }
  }
  .text-field {
    margin: 0;
  }
  .team-names {
    margin-top: 20px;
  }
  .onboard-team {
    @include tank-font($font-size-15px, $font-weight-bold, $font-family-semibold);
    color: $primary-black;
    .create-team {
      margin-left: 5px;
      letter-spacing: 0.6px;
      @include tank-font($font-size-15px, $font-weight-600, $font-family-regular);
    }
  }
  .no-teams,
  .err-msg {
    @include tank-font($font-size-15px, $font-weight-600, $font-family-semibold);
    color: $primary-black;
    text-align: center;
    margin-top: 15px;
  }
  .err-msg {
    color: $err-red;
  }
  .btn-block {
    text-align: right;
    button,
    a {
      @include button-small;
      &.Mui-disabled {
        background-color: $disabled-btn;
      }
    }
  }
  .get-started-user-email {
    @include tank-font($font-size-15px, $font-weight-600, $font-family-semibold);
    text-align: center;
  }

  .sign-in-wrapper {
    @include bordered-box;
    .sign-in-intro.sign-in-item {
      @include mobile-and-tablet {
        display: none;
      }
      @include desktop-and-large-screen {
        padding: 67px 47px 60px;
      }
    }
    .intro-text {
      margin-bottom: 45px;
    }
    h2 {
      margin: 0 0 8px 0;
      @include tank-font($font-size-35px, $font-weight-bold, $font-raleway-bold);
      color: $color-black;
    }
    p {
      @include tank-font($font-size-12px, $font-weight-medium, $font-raleway-medium);
      &:not(.Mui-error) {
        color: $grey-dark;
        margin: 0;
        line-height: 1.5;
        font-size: $font-size-14px;
      }
    }
    .sign-in-asset {
      height: 288px;
      img {
        @include image-fit;
      }
    }
    .sign-in-form {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile-and-tablet {
        width: 100%;
        max-width: none;
        flex-basis: auto;
      }
      @include desktop-and-large-screen {
        padding: 12px 60px;
        background-color: #FAFAFA;
      }
      h2 {
        margin: 0 0 33px 0;
        text-align: center;
      }
      .form-field {
        width: 100%;
        margin-bottom: 20px;
        &.password {
          margin-bottom: 12px;
        }
      }
      label {
        @include tank-font($font-size-15px, $font-weight-600, $font-raleway-medium);
        color: $grey-dark;
      }
      label[class*="error"] {
        color: $err-red;
      }
      input {
        @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
        color: $color-black;
        &:disabled {
          color: $color-dark-grey;
        }
      }
      .forgot-password {
        float: right;
        text-align: right;
        margin-bottom: 28px;
        color: $grey-dark;
        @include tank-font($font-size-14px, $font-weight-medium, $font-raleway-medium);
        &:hover {
          text-decoration: underline !important;
        }
      }
      .form-err {
        margin-bottom: 15px;
      }
      .join-free {
        text-align: center;
        margin-bottom: 8px;
        a {
          color: $primary-color;
        }
      }
    }
    .form-action-btns {
      @include mobile-and-tablet {
        margin-bottom: 35px;
      }
    }
    .primary-btn {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
    }
    .sign-in-item {
      @include desktop-and-large-screen {
        padding-top: 67px;
      }
    }
    p.not-verified-msg {
      color: $color-red;
      margin-top: 12px;
      text-align: center;
      position: relative;
      span {
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
      .tank-spinner {
        right: -5px;
      }
    }
    p.error {
      text-align: center;
      color: $color-red;
    }
  }
}
