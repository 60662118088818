@import './variables.scss';
@import './screens_mixins.scss';

@font-face {
  font-family: 'SFUIDisplay-Regular';
  src: url('../assets/fonts/SF-UI-Display/SFUIDisplay-Regular.eot') format('embedded-opentype'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Regular.ttf') format('truetype'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Regular.woff') format('woff'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Regular.woff2') format('woff2');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'SFUIDisplay-Medium';
  src: url('../assets/fonts/SF-UI-Display/SFUIDisplay-Medium.eot') format('embedded-opentype'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Medium.ttf') format('truetype'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Medium.woff') format('woff'),
       url('../assets/fonts/SF-UI-Display/SFUIDisplay-Medium.woff2') format('woff2');
  font-weight: $font-weight-600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'SF-UI-Display-Semibold';
  src: url('../assets/fonts/SF-UI-Display/SF-UI-Display-Semibold.eot') format('embedded-opentype'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Semibold.ttf') format('truetype'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Semibold.woff') format('woff'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Semibold.woff2') format('woff2');
       font-weight: $font-weight-600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'SF-UI-Display-Bold';
  src: url('../assets/fonts/SF-UI-Display/SF-UI-Display-Bold.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Bold.ttf') format('truetype'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Bold.woff') format('woff'),
       url('../assets/fonts/SF-UI-Display/SF-UI-Display-Bold.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('../assets/fonts/Raleway/Raleway-Bold.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-Bold.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-Bold.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Raleway-Light';
  src: url('../assets/fonts/Raleway/Raleway-Light.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-Light.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-Light.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-Light.woff2') format('woff2');
  font-weight: $font-weight-300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Raleway-Regular';
  src: url('../assets/fonts/Raleway/Raleway-Regular.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-Regular.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-Regular.woff2') format('woff2');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('../assets/fonts/Raleway/Raleway-SemiBold.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2');
  font-weight: $font-weight-600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Raleway-ExtraBold';
  src: url('../assets/fonts/Raleway/Raleway-ExtraBold.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Raleway-Medium';
  src: url('../assets/fonts/Raleway/Raleway-Medium.eot') format('eoembedded-opentypet'),
       url('../assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype'),
       url('../assets/fonts/Raleway/Raleway-Medium.woff') format('woff'),
       url('../assets/fonts/Raleway/Raleway-Medium.woff2') format('woff2');
  font-weight: $font-weight-600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'dm-sans-normal';
  src: url('../assets/fonts/dm-sans/dm-sans-normal.woff2') format('woff2');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'dm-sans-500';
  src: url('../assets/fonts/dm-sans/dm-sans-500.woff2') format('woff2');
  font-weight: $font-weight-medium;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'dm-sans-600';
  src: url('../assets/fonts/dm-sans/dm-sans-600.woff2') format('woff2');
  font-weight: $font-weight-600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'dm-sans-700';
  src: url('../assets/fonts/dm-sans/dm-sans-700.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'dm-sans-800';
  src: url('../assets/fonts/dm-sans/dm-sans-800.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'dm-sans-900';
  src: url('../assets/fonts/dm-sans/dm-sans-900.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: auto;
}

@mixin tank-font($size, $weight: $font-weight-normal, $family: $font-family-normal) {
  font-size: $size;
  font-weight: $weight;
  font-family: $family;
}

@mixin screen-max-width {
  max-width: $screen-max-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 $mobile-gutter-space;
  @include desktop-and-large-screen {
    padding: 0 $desktop-gutter-space;
  }
}

@mixin question-modal {
  .enter-ques-title {
    margin: 0 0 22px;
    font-size: $font-size-14px;
    padding: 11px 14px;
    outline: 0;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-grey;
    fieldset {
      border-width: 1px !important;
      border: 1px solid $color-grey !important;
    }
  }
  .input-label {
    font-weight: $font-weight-medium;
    display: block;
  }
}

@mixin section-spacing {
  margin-bottom: $margin-bottom-mobile;
  @include desktop-and-large-screen {
    margin-bottom: $margin-bottom-desktop;
  }
}

@mixin image-fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@mixin button-small {
  min-width: 100px;
  max-width: 127px;
  padding: 9px;
  line-height: normal;
  color: $color-white;
  span {
    color: $color-white;
    font-size: $font-size-16px;
  }
}

@mixin button-mini {
  background-color: $primary-color;
  min-width: auto;
  padding: 0.4375rem 0.75rem;
  line-height: normal;
  span {
    color: $color-white;
    font-size: $font-size-13px;
  }
}

@mixin grey-btn {
  background-color: $color-light-grey;
  span {
    color: $primary-black;
  }
}

@mixin text-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin no-scroll-bar {
  // -ms-overflow-style: none;  // IE 10+
  // scrollbar-width: none; // Firefox
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

@mixin hover-move {
  transform: translate3d(0px, 0px, 0px);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translate3d(0px, -8px, 0px);
  }
}

@mixin box-effect {
  background-image: linear-gradient(180deg, #f7fcff 6%, #ffffff 100%);
  box-shadow: 0 20px 26px 0 rgba(29, 38, 100, 0.19), inset 0 1px 0 0 #ffffff;
}

@mixin box-color-gradient {
  box-shadow: 0px 4px 18px -5px rgba(0, 0, 0, 0.25);
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(252, 255, 119, 0.8), rgba(255, 150, 244, 0.4), rgba(51, 191, 251, 0.4), rgba(106, 254, 183, 0.4), rgba(255, 224, 165, 0.8));
    z-index: -1;
    filter: blur(15px);
    left: 0;
    top: 0;
  }
}

@mixin bordered-box {
  border: 1px solid #F1F0F0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background-color: $color-white;
  padding: 10px;
  @include mobile-and-tablet {
    width: 100%;
    margin: 0;
  }
  @include desktop-and-large-screen {
    max-width: $bordered-box-width;
    margin: 0 auto;
  }
}
