@import '../../scss/custom_mixins.scss';

$smallAvatarIcon: 25px;
$mediumAvatarIcon: 38px;
$largeAvatarIcon: 3.125rem;

.user-dp {
  display: block;
  margin-right: 11px;
  &.small {
    width: $smallAvatarIcon;
    height: $smallAvatarIcon;
  }
  &.medium {
    width: $mediumAvatarIcon;
    height: $mediumAvatarIcon;
  }
  &.large {
    width: $largeAvatarIcon;
    height: $largeAvatarIcon;
  }
  img {
    @include image-fit;
    border-radius: 6.25rem;
  }
}
