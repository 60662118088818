@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';
@import '../../scss/screens_mixins.scss';

.terms-conditions-page {
  .tnc-container {
    max-width: $compact-width;
    margin-bottom: 180px;
    .terms-of-use-title {
      @include tank-font($font-size-50px, $font-weight-bold, $font-raleway-bold);
    }
    .content {
      margin-bottom: 50px;
      &:last-child {
        margin: 0;
      }
      h2 {
        @include tank-font($font-size-32px, $font-weight-bold, $font-raleway-bold);
        margin: 0 0 5px
      }
      p,
      li {
        @include tank-font($font-size-16px, $font-weight-normal, $font-raleway-medium);
        margin: 0;
        line-height: 26px;
      }
      p {
        margin-bottom: 28px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
