@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.tank-btn {
  text-align: left;
  button {
    border-radius: 2px;
    background-color: #5171ff !important;
    padding: 8px 40px;
    span {
      color: $color-white;
      text-transform: none;
      @include tank-font($font-size-16px, $font-weight-600, $font-family-semibold);
    }
    &:disabled {
      background-color: $divided-grey-secondary !important;
    }
  }
}
