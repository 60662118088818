@import '../../scss/variables';

.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  // height: 100%;
  .text-field {
    box-sizing: border-box;
  }
}
