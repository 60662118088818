@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.BillingDetailsPage {
  .billing-page-title-container {
    margin-bottom: 43px;
    .page-heading {
      @include tank-font($font-size-32px, $font-weight-bold, $font-raleway-bold);
      margin: 0 0 6px;
    }
    .page-subheading {
      margin: 0;
      @include tank-font($font-size-15px, $font-weight-600, $font-raleway-regular);
      color: $color-desc-grey;
    }
  }
  .billing-plan-info-container {
    border: 1px solid $color-grey;
    border-radius: 8px;
    padding: 30px 62px;
    .billing-plan-info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 43px;
      .plan-amount-and-upgrade {
        display: flex;
        align-items: flex-start;
        .plan-amount {
          margin-right: 26px;
        }
        .amount {
          text-align: right;
          margin: 0;
          @include tank-font($font-size-18px, $font-weight-bold, $font-raleway-bold);
        }
        .trial-info {
          margin: 0;
          color: $color-desc-grey;
          @include tank-font($font-size-12px, $font-weight-600, $font-raleway-regular);
        }
        .upgrade-btn {
          width: auto;
          border: 0;
          font-family: $font-raleway-semibold;
        }
      }
    }
    .billing-plan-info {
      .plan-info {
        @include tank-font($font-size-24px, $font-weight-bold, $font-raleway-bold);
        margin: 0 0 2px;
      }
      .plan-description {
        margin: 0;
        color: $color-desc-grey;
        @include tank-font($font-size-12px, $font-weight-600, $font-raleway-regular);
      }
    }
    .billing-card-details {
      border: 1.5px solid $table-head-grey;
      background-color: $bg-grey;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .billing-card {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
        .card-img-wrapper {
          .card-img {
            margin: 0;
            height: 44px;
            border: 1.5px solid $table-head-grey;
            border-radius: 6px;
            background-color: $color-white;
            svg {
              height: 100%;
            }
          }
        }
      }
      .card-details {
        margin-left: 20px;
        .card-num {
          @include tank-font($font-size-16px, $font-weight-bold, $font-raleway-bold);
        }
        .card-exp {
          color: $color-desc-grey;
          @include tank-font($font-size-14px, $font-weight-600, $font-raleway-regular);
        }
        .card-email {
          @include tank-font($font-size-14px, $font-weight-600, $font-raleway-regular);
        }
      }
      .edit-card-container {
        display: none;
      }
      .edit-card-btn {
        background-color: #000;
        color: #fff;
        border: 0;
        padding: 12px;
        padding: 10px 22px;
        border-radius: 6px;
        @include tank-font($font-size-14px, $font-weight-600, $font-raleway-bold);
      }
    }
  }
}
