@import '../../../scss/variables';

.invite-members {
  .modal-content {
    min-height: 377px;
    width: 500px;
  }
  .repeater-control {
    margin-bottom: 30px;
  }
}
