@import '../../../../scss/variables';

.billing-form {
  margin-bottom: 150px;
  margin-top: 70px;
  .input-field {
    input {
      height: 40px;
    }
  }
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    box-shadow: $box-shadow;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
}
