@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.contact-us-page {
  @include desktop-and-large-screen {
    margin-top: 9.0625rem;
    margin-bottom: 6.25rem;
  }
  .contact-us.wrap {
    @include desktop-and-large-screen {
      max-width: 70rem;
    }
    margin: 0 auto;
    background-color: $color-white;
    border-radius: 0.625rem;
    padding: 3.4375rem 25px;
    @include desktop-and-large-screen {
      padding: 3.4375rem 3.75rem;
    }
    box-shadow: $box-shadow;
    .title,
    .desc {
      color: $primary-black;
    }
    .title {
      @include tank-font($font-size-40px, $font-weight-bold, $font-raleway-bold);
      margin: 0 0 25px;
      max-width: 25.5rem;
      line-height: 3.125rem;
    }
    .desc {
      @include tank-font($font-size-16px, $font-weight-medium, $font-raleway-semibold);
      margin: 0;
      max-width: 24.6875rem;
      margin-bottom: 2.1875rem;
    }
    .input-field {
      .text-field {
        @include desktop-and-large-screen {
          max-width: 25rem;
        }
        border-radius: 0.5rem;
        &:not(.description) {
          height: 2.6875rem;
        }
        &.description {
          margin-bottom: 1.875rem;
          height: 7.1875rem;
          padding-top: 0.875rem;
        }
      }
    }
    .submit-btn {
      @include mobile-and-tablet {
        text-align: center;
      }
    }
  }
  .contact-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-container {
    max-width: 376px;
    height: 370px;
    margin-bottom: 40px;
    @include mobile-and-tablet {
      display: none;
    }
  }
  .social-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    .social-logo {
      width: 50px;
      height: 80px;
      margin-right: 23px;
      &:last-child {
        margin: 0
      }
    }
  }
  .img-container,
  .social-contact {
    img {
      @include image-fit;
      object-fit: contain;
    }
  }
  &.posted {
    .contact-us.wrap {
      max-width: 40rem;
    }
    h2 {
      @include tank-font($font-size-32px, $font-weight-medium, $font-raleway-bold);
    }
  }
}
