@import '../../scss/variables';

body {
  .MuiSelect-select {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    right: 10px;
  }
  .dropdown-comp {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    position: relative;
    .MuiInput-underline {
      &:before,
      &:after {
        border: 0 !important;
      }
    }
    .selected-option {
      font-family: $font-family-regular;
    }
    .MuiSelect-select.MuiSelect-select {
      height: 23px;
      font-size: $font-size-14px;
      display: flex;
      align-items: center;
      padding: 9px;
      padding: 7px 13px;
      border-radius: 4px;
      &.Mui-disabled {
        background-color: $color-light-grey !important;
      }
    }
    .selected-color {
      width: 25px;
      height: 25px;
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      border-radius: 4px;
    }
    &.colors {
      width: 100%;
      min-width: auto;
      margin-bottom: 0;
      .MuiInputBase-formControl {
        z-index: 5;
      }
    }
  }
  .color-dropdown {
    transition: none;
    background-color: transparent !important;
    padding: 5px;
    > .MuiTouchRipple-root {
      display: none;
    }
  }
  .dropdown-menu-item {
    font-size: $font-size-14px;
    font-family: $font-family-regular;
  }
  .MuiList-root.MuiMenu-list[aria-labelledby="select-dropdown"] {
    max-height: 190px;
    overflow-y: scroll;
  }
  .label-tooltip {
    display: flex;
    .input-field {
      margin-right: 8px;
    }
    .tooltip-icon {
      position: relative;
      top: 2px;
    }
  }
}
