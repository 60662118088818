@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.tank-search {
  position: relative;
  input {
    padding: 0.4375rem 2.1875rem;
    border-radius: 6.25rem;
    border: 0;
    color: $color-black;
    outline: 0;
    width: 24.625rem;
    height: 2.375rem;
    font-size: $font-size-14px;
    border: 1px solid $color-light-grey;
    box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
    &::placeholder {
      @include tank-font($font-size-15px, $font-weight-medium, $font-family-medium);
    }
    &::-webkit-input-placeholder {
      @include tank-font($font-size-15px, $font-weight-medium, $font-family-medium);
    }
    &::-moz-placeholder {
      @include tank-font($font-size-15px, $font-weight-medium, $font-family-medium);
    }
    @include mobile-and-tablet {
      width: 100%;
    }
  }
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .search-results-dropdown {
    position: absolute;
    width: 100%;
    background-color: $color-white;
    z-index: 1;
    .MuiPaper-root {
      width: 100%;
      min-height: 70px;
      max-height: 250px;
      height: auto;
      overflow: hidden;
      overflow-y: scroll;
      margin: 2px 0;
      position: relative;
      &.is-loading {
        background: $color-off-white;
      }
      &.center-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tank-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .no-results {
      margin: 0;
      @include tank-font($font-size-15px, $font-weight-medium, $font-family-medium);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .search-result {
      padding: 16px 20px;
      &:first-child a {
        padding-top: 0;
      }
      &:last-child {
        margin: 0;
        a {
          border: 0;
          padding: 0;
        }
      }
      a {
        display: block;
        padding: 0 0 16px;
      }
      .result-item {
        padding: 5px 8px;
        &:hover {
          cursor: pointer;
          background: $color-light-grey;
        }
      }
      .result-group {
        margin: 0;
        @include tank-font($font-size-10px, $font-weight-bold, $font-family-bold);
        color: $color-dark-grey;
      }
      .answer-results {
        margin-top: 17px;
        border-top: 1px solid $divided-grey-secondary;
        padding-top: 17px;
      }
    }
    .result-title {
      @include tank-font($font-size-13px, $font-weight-medium, $font-family-medium);
      margin-bottom: 3px;
      color: $color-text-grey;
      p {
        margin: 0;
      }
      img {
        display: none;
      }
    }
    .result-author {
      font-size: 11px;
      font-family: $font-family-regular;
      margin: 0;
      color: $color-text-grey;
      letter-spacing: 0.4px;
    }
  }
}
