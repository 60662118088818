@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.search-dropdown {
  margin-bottom: 1.875rem;
  .title {
    color: $primary-black;
    margin-bottom: 0.5rem;
    @include tank-font($font-size-15px, $font-weight-medium, $font-family-semibold);
  }
  .MuiAutocomplete-inputRoot {
    border-radius: 6px;
  }
  fieldset {
    border: 1px solid $color-grey !important;
  }
  input {
    padding: 0.25rem !important;
    font-size: $font-size-14px;
  }
  .MuiChip-deletable {
    background-color: $color-light-grey;
    border: 1px solid $table-head-grey;
    .MuiChip-label {
      color: $primary-black;
      @include tank-font($font-size-14px, $font-weight-medium, $font-family-regular);
    }
  }
  .MuiAutocomplete-listbox {
    @include no-scroll-bar;
  }
  .MuiAutocomplete-option {
    color: $primary-black;
    @include tank-font($font-size-14px, $font-weight-medium, $font-family-regular);
    transition: background-color 0.15s ease-in;
    span {
      padding: 0.3125rem;
    }
    .MuiIconButton-label {
      padding: 0;
    }
  }
}
