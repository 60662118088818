@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.MuiBackdrop-root.Loader__Backdrop {
  z-index: 1;
  top: 80px;
  position: absolute;
  background-color: rgba(181, 181, 181, 0.5);
  height: 100vh;
  flex-direction: column;
  .MuiCircularProgress-root {
    color: $darker-blue;
  }
  .fetching-data-label {
    @include tank-font($font-size-18px, $font-weight-medium, $font-family-semibold);
    margin: 5px 0 0;
  }
}
