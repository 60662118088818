@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.user-onboard {
  margin: 0 auto;
  width: 450px;
  box-shadow: $box-shadow;
  border-radius: 6px;
  .stepper-content {
    padding: 40px;
    background-color: $color-white;
    border-radius: 8px;
    position: relative;
    h3 {
      margin-bottom: 25px;
      @include tank-font($font-size-20px, $font-weight-medium, $font-family-medium);
    }
    .onboard-info-block {
      margin-bottom: 10px;
    }
  }
}
