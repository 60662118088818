@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.password-reset-page,
.forgot-pw-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  text-align: center;
  .logo-wrapper {
    margin-bottom: 25px;
    img {
      height: 40px;
    }
  }
  .forgot-pw-body {
    margin-bottom: 30px;
    .forgot-pw {
      &-title {
        @include tank-font($font-size-22px, $font-weight-600, $font-raleway-semibold);
      }
      &-desc {
        @include tank-font($font-size-17px, $font-weight-medium, $font-raleway-medium);
        color: $color-desc-grey;
        margin: 0;
      }
    }
  }
  .pw-reset-form {
    text-align: left;
    background-color: $color-light-grey;
    padding: 25px;
    border-radius: 6px;
    @include tablet-and-desktop {
      max-width: 455px;
      margin: 0 auto;
    }
    label {
      margin-bottom: 8px;
      color: $color-black;
      @include tank-font($font-size-15px, $font-weight-600, $font-raleway-semibold);
    }
    input {
      background-color: $color-white;
      height: 35px;
      box-sizing: border-box;
      &:last-of-type {
        margin-bottom: 25px;
      }
    }
    .reset-btn-container {
      position: relative;
      display: flex;
      align-items: center;
      .pw-reset {
        background-color: $primary-color-light;
      }
    }
    .cancel-btn {
      text-align: center;
      display: block;
      color: $primary-color;
      @include tank-font($font-size-15px, $font-weight-600, $font-raleway-semibold);
      margin-top: 27px;
    }
    &.reset-err {
      input {
        margin-bottom: 8px;
      }
    }
  }
  .email-confirmation-wrapper {
    @include tablet-and-desktop {
      margin: 0 auto;
      max-width: 400px;
    }
    .email-confirm-asset {
      height: 200px;
      margin-bottom: 25px;
      img {
        @include image-fit;
      }
    }
    p {
      color: $color-desc-grey;
      @include tank-font($font-size-16px, $font-weight-medium, $font-raleway-medium);
      margin-bottom: 5px;
    }
    .entered-email {
      color: $color-black;
      font-weight: 600;
      font-family: $font-raleway-semibold;
    }
    .try-again-container {
      position: relative;
      .tank-spinner {
        top: 1px;
      }
    }
    .try-again {
      color: $primary-color;
      font-weight: 600;
      font-family: $font-raleway-semibold;
      text-decoration: underline;
      cursor: pointer;
      &.disable-link {
        cursor: auto;
        pointer-events: none;
        color: $primary-color-light;
      }
    }
    .email-confirm-title {
      @include tank-font($font-size-18px, $font-weight-600, $font-raleway-semibold);
      color: $color-black;
      margin-bottom: 10px;
    }
  }
  &.pw-reset {
    .form-err {
      color: $err-red;
      margin-bottom: 18px;
      @include tank-font($font-size-12px, $font-weight-600, $font-raleway-semibold);
    }
  }
}
