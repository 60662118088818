@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.my-posts {
  .no-posts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      @include tank-font($font-size-35px, $font-weight-medium, $font-family-semibold);
    }
    p {
      @include tank-font($font-size-16px, $font-weight-normal, $font-family-regular);
    }
    .no-data-img {
      width: 435px;
      img {
        @include image-fit;
      }
    }
  }
  .question-dash {
    margin-top: 35px;
  }
  .my-post-type {
    @include tank-font($font-size-32px, $font-weight-bold, $font-family-bold);
    color: $primary-black;
    margin: 0 0 35px;
    position: relative;
    padding-bottom: 6px;
    &:after {
      content: '';
      border-bottom: 1px solid $grey-dark;
      position: absolute;
      bottom: 0;
      width: 280px;
      left: 0;
    }
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      background-color: $grey-dark;
      position: absolute;
      bottom: -3px;
      border-radius: 100%;
      left: 280px;
    }
    &.shimmer {
      margin-top: 35px;
    }
  }
}
