@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';
@import '../../scss/screens_mixins.scss';

.policy-page {
  .policy-page-wrapper {
    @include desktop-and-large-screen {
      max-width: $compact-width;
    }
    .policy-title {
      @include tank-font($font-size-50px, $font-weight-bold, $font-raleway-bold);
    }
    .content {
      margin-bottom: 60px;
      &:last-child {
        margin: 0;
      }
      h1 {
        @include tank-font($font-size-35px, $font-weight-medium, $font-raleway-semibold);
        line-height: normal;
        text-transform: none;
        @include large-screen {
          font-size: $font-size-45px;
          letter-spacing: normal;
          line-height: 50px;
        }
      }
      
      h2 {
        @include tank-font($font-size-24px, $font-weight-normal, $font-raleway-semibold);
        letter-spacing: normal;
        line-height: normal;
        text-transform: none;
        margin: 0;
        @include large-screen {
          font-size: $font-size-35px;
          letter-spacing: normal;
          line-height: normal;
        }
      }
      
      h3 {
        @include tank-font($font-size-24px, $font-weight-medium, $font-raleway-medium);
        text-transform: none;
        letter-spacing: normal;
        line-height: normal;
        margin: 0;
      }
      p,
      li {
        @include tank-font($font-size-16px, $font-weight-normal, $font-raleway-medium);
        line-height: 26px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        @include tank-font($font-size-16px, $font-weight-bold, $font-raleway-bold);
      }
      h1,
      h2,
      h3 {
        margin: 0 0 8px;
      }
    }
  }
  .policy-container {
    margin-bottom: 180px;
  }
}
