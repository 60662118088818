@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.add-integration {
  max-width: 34rem;
  padding: 0;
  margin: 0 auto;
  .dropdown-comp {
    margin-bottom: 2.5rem;
  }
  .input-label,
  .title {
    color: $primary-black;
    margin-bottom: 0.5rem;
    @include tank-font($font-size-24px, $font-weight-bold, $font-family-bold);
  }
  .btn-block {
    text-align: right;
  }
  .search-dropdown {
    margin-top: 35px;
  }
  .slack-install-placeholder {
    min-height: 160px;
  }
  .confetti-container {
    width: 100%;
    height: 160px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .slack-install-success {
      margin: 0;
      display: flex;
      align-items: center;
      @include tank-font($font-size-18px, $font-weight-bold, $font-family-bold);
      svg {
        margin-right: 6px;
        color: $color-green;
      }
    }
    canvas {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.onboard-successfull {
  .modal-content {
    max-width: 450px;
    .confirm-delete-msg {
      max-width: 360px;
      margin: 0 0 20px;
      @include tank-font($font-size-18px, $font-weight-medium, $font-family-medium);
    }
  }
}
