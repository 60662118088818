@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.preview-answer-modal {
  .modal-content {
    width: 45%;
    min-height: 420px;
    padding: 30px 33px 30px;
  }
  .preview-content-entry {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
    a {
      @include tank-font($font-size-15px, $font-weight-bold, $font-family-bold);
    }
  }
  .content-label {
    @include tank-font($font-size-15px, $font-weight-medium, $font-family-semibold);
    margin-bottom: 3px;
    color: $primary-black;
  }
  .timestamp {
    margin: 0;
  }
}
