body .tank-loader {
  height: 25px;
  width: 150px;
  padding: 0;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
