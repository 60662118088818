@import "../../scss/variables";
@import '../../scss/custom_mixins.scss';

.dashboard {
  &.screen-max-width {
    max-width: none;
  }
  .dashboard-heading {
    text-align: center;
    border-bottom: 2px solid;
    margin: 0 auto;
    max-width: 685px;
    h2 {
      margin-bottom: 33px;
    }
  }
  .stats-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    .stat-item {
      display: flex;
      align-items: center;
      padding-right: 26px;
      &:last-child {
        padding: 0;
      }
      span:not(.flash) {
        padding-left: 5px;
      }
    }
  }
  .greeting-block {
    margin: 140px 0 35px;
  }
}

.dashboard-side-nav {
  width: 270px;
  height: 100vh;
  &.disable-side-nav {
    opacity: 0.8;
    pointer-events: none;
  }
  .collections-list {
    .collection-item .MuiListItem-button {
      padding: 5px 10px 5px 33px;
    }
  }
  .empty-cell {
    min-height: $header-height;
  }
  .menu-items {
    display: flex;
    align-items: center;
    position: relative;
    a {
      display: block;
      width: 100%;
      padding: 11px;
      border: 2px solid transparent;
    }
    .MuiSvgIcon-root {
      position: absolute;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .drawer-item {
      margin: 0;
      padding: 11px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      border: 2px solid #717882;
      border-radius: 6px;
    }
  }
  @include mobile-and-tablet {
    .sidebar.logo {
      height: 48px;
    }
    padding-top: 20px;
  }
}

.tank-drawer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: auto;
  .new-ques-btn button {
    width: 100%;
    padding: 0.3125rem 2.5rem;
  }
  &.settings {
    .settings-drawer {
      padding: 25px 7px;
      p {
        margin-bottom: 0;
        @include tank-font($font-size-15px, $font-weight-600, $font-family-semibold);
      }
      .team-name-title {
        margin-bottom: 5px;
      }
      .team-name {
        font-size: $font-size-17px;
      }
    }
  }
}

.desktop-drawer .MuiDrawer-paperAnchorDockedLeft {
  height: 100vh;
  flex-grow: 1;
}

.sidebar-items-container {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  .empty-block {
    flex-grow: 1;
  }
}

.sidebar.logo {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  a {
    height: 40px;
  }
}

.user-welcome {
  text-align: center;
  margin: 35px 0;
  .user-img {
    $border: 3px;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 100px;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(to right, red, orange);
    }
    img {
      border-radius: 100px;
      width: 100%;
      height: 100%;
    }
  }
  .user-team {
    @include tank-font($font-size-18px, $font-weight-medium, $font-family-semibold);
  }
}

.trial-expiry,
.subscription-expiry {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btn-navigate {
    width: auto;
  }
}

.dashboard-content {
  .Loader__Backdrop {
    top: 0;
  }
}
