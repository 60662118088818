@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.billing-page,
.billing-plan {
  h2 {
    margin: 0;
    @include tank-font($font-size-20px, $font-weight-normal, $font-family-regular);
  }
  .plan-price {
    margin-bottom: 20px;
    display: block;
  }
  .submit-btn {
    text-align: center;
  }
  .selected-plan-price {
    @include tank-font($font-size-30px, $font-weight-bold, $font-family-bold);
    .plan-period {
      @include tank-font($font-size-14px, $font-weight-bold, $font-family-bold);
      vertical-align: middle;
      color: $color-dark-grey;
    }
  }
  button {
    margin-bottom: 20px;
    padding: 6px 33px;
  }
  .plan-details {
    padding: 35px;
    background-color: $color-white;
    border-radius: 12px;
    box-shadow: $box-shadow-secondary;
    height: 100%;
    p {
      &:last-child {
        margin: 0;
      }
    }
    button {
      width: 100%;
    }
    &.active-plan {
      border: 2px solid $brand-color-dark;
    }
  }
  .plan-duration {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    button {
      margin: 0 10px 0 0;
      width: 75px;
      &:last-child {
        margin: 0;
      }
    }
    .mini:not(.active) {
      @include grey-btn;
    }
  }
}

.billing-plan {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  .plan-duration {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    button {
      margin: 0 10px 0 0;
      width: 75px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
