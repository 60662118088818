@import '../../../scss/variables';
@import '../../../scss/screens_mixins.scss';

.new-collection {
  .create-collection {
    &.creating-collec {
      display: flex;
      justify-content: center;
    }
  }
  .create-collec-text {
    padding: 0;
    margin-bottom: 25px;
  }
  .collection-modal-data {
    margin: 0 0 18px;
    padding: 0;
  }
  .modal-content {
    width: calc(100% - 50px);
    @include tablet {
      width: 470px;
    }
    @include desktop-and-large-screen {
      width: 537px;
      min-height: 453px;
    }
  }
  .search-dropdown {
    margin: 0;
  }
  .dd-group {
    align-items: center;
  }
}
