@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.team-settings {
  color: $primary-black;
  margin-bottom: 7.5rem;
  // margin-top: 9.375rem;
  .admin-controls {
    display: flex;
    align-items: center;
    .err-msg {
      margin-right: 15px;
    }
  }
  .team-config {
    border-bottom: 1px solid $table-head-grey;
    margin-bottom: 2.1875rem;
    &:last-child {
      margin: 0;
      border: 0;
      .config-content {
        padding-bottom: 0;
      }
    }
  }
  .team-name {
    .input-field {
      margin-bottom: 15px;
    }
    .reset {
      margin-right: 10px;
    }
  }
  .section-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title,
  .search-dropdown .title {
    color: $primary-black;
    margin-bottom: 0.3125rem;
    @include tank-font($font-size-28px, $font-weight-bold, $font-family-bold);
  }
  .primary-btn {
    @include button-small;
    min-width: 5rem;
    padding: 0.4375rem 0.875rem;
    height: 2.1875rem;
    box-shadow: none !important;
    span {
      font-size: $font-size-15px;
    }
  }
  .help-text {
    margin: 0;
    color: $grey-dark;
    @include tank-font($font-size-14px, $font-weight-600, $font-family-semibold);
    padding-bottom: 0.9375rem;
  }
  .config-content {
    margin-top: 0.625rem;
    padding-bottom: 1.875rem;
    .text-field {
      width: 16.875rem;
      height: 2.5rem;
      margin-bottom: 0;
    }
  }
  .table-card {
    margin-bottom: 0;
  }
  .member {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-light-grey;
    position: relative;
    &:last-child {
      border: 0;
    }
    p {
      margin: 0;
    }
    .name {
      @include tank-font($font-size-16px, $font-weight-600, $font-family-semibold);
    }
    .role {
      padding: 15px;
      p {
        margin: 0;
      }
      .role-title {
        @include tank-font($font-size-14px, $font-weight-600, $font-family-semibold);
      }
      .role-desc {
        color: $grey-dark;
        @include tank-font($font-size-13px, $font-weight-medium, $font-family-medium);
      }
    }
    .member-actions {
      display: flex;
      align-items: center;
      button {
        margin-right: 15px;
      }
      *:hover {
        cursor: pointer;
      }
      .delete-member {
        position: absolute;
        right: 8px;
      }
    }
  }
  .integration {
    p {
      margin: 0;
    }
    .config-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.5625rem;
      &.slack {
        display: block;
      }
    }
    .slack-users {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .search-dropdown {
        margin: 0 40px 0 0;
        width: 100%;
      }
    }
    .integration-method {
      display: flex;
      align-items: center;
      p {
        @include tank-font($font-size-16px, $font-weight-600, $font-family-semibold);
      }
    }
    img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1.25rem;
    }
  }
  .deletion {
    .primary-btn {
      background-color: $color-red;
    }
  }
  .btns-container {
    position: relative;
    display: inline-flex;
    .tank-spinner {
      right: -25px;
      top: 8px;
    }
  }
}
