@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';
@import '../../scss/screens_mixins.scss';

.integrations-page {
  .integrations-container {
    @include mobile-and-tablet {
      padding: 0;
    }
    @include desktop-and-large-screen {
      max-width: $compact-width;
    }
    .integration-title {
      @include tank-font($font-size-65px, $font-weight-bold, $font-raleway-bold);
      color: $color-black;
      text-align: center;
      line-height: 84px;
      margin-bottom: 100px;
    }
    .slack {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .slack-label {
        @include tank-font($font-size-24px, $font-weight-medium, $font-raleway-semibold);
        margin: 0;
      }
      button {
        padding: 8px 33px;
        span {
          font-size: $font-size-16px;
        }
      }
    }
    .slack-desc {
      @include tank-font($font-size-18px, $font-weight-normal, $font-raleway-medium);
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
  .features-list {
    margin-bottom: 80px;
    .feature {
      margin-bottom: 40px;
      &:last-child {
        margin: 0;
      }
      img {
        border-radius: 6px;
      }
      &:first-child {
        img {
          filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
  img {
    @include image-fit;
  }
  .slack-asset,
  .feature {
    @include desktop-and-large-screen {
      height: 570px;
    }
  }
}
