@import '../../scss/variables';

$color-box-dimension: 48px;

.color-palette {
  .PrivateRadioButtonIcon-root-48 {
    color: $color-white;
    width: $color-box-dimension;
    border: 1px solid $color-white;
    height: $color-box-dimension;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
  }
  .color-container {
    position: relative;
    width: $color-box-dimension;
    height: $color-box-dimension;
    display: inline-flex;
  }
  label {
    margin: 0;
    display: inherit;
    width: 100%;
  }
  .color-box {
    width: $color-box-dimension;
    height: $color-box-dimension;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
  }
  .palette-group {
    flex-wrap: nowrap;
    flex-direction: row;
    .color-option {
      padding: 0;
      display: inherit;
      width: 100%;
      height: 100%;
      .MuiTouchRipple-root {
        z-index: 10;
      }
      input {
        margin: 0;
      }
    }
    .color-option.Mui-checked .MuiTouchRipple-root:after {
      content: '';
      display: block;
      width: 11px;
      height: 21px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      left: 18px;
      top: 11px;
    }
    .MuiGrid-container {
      width: 157px;
      .MuiGrid-item {
        flex-basis: 29.333333%;
        margin: 2px;
      }
    }
  }
  .some-name {
    position: absolute;
    font-size: $font-size-8px;
  }
}
