@import '../../scss/variables';
@import '../../scss/screens_mixins.scss';
@import '../../scss/custom_mixins.scss';

.footer-section {
  background-color: $color-white;
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
    @include tablet-and-desktop {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .footer-container {
    width: 100%;
    margin: 0;
    .MuiGrid-item {
      padding: 0;
    }
  }
  .brand-logo {
    margin-bottom: 14px;
    height: 34px;
    @include desktop-and-large-screen {
      margin-bottom: 27px;
    }
  }
  .links-block,
  .social-links-block {
    margin-bottom: 40px;
    @include tablet-and-desktop {
      margin-bottom: 45px;
    }
  }
  .footer-title {
    @include tank-font($font-size-18px, $font-weight-bold, $font-family-dm-sans-700);
    margin-bottom: 8px;
    color: $color-dark-grey;
    @include desktop-and-large-screen {
      margin-bottom: 20px;
    }
  }
  .footer-links-container {
    @include mobile-and-tablet {
      margin-bottom: 28px;
    }
    .footer-links {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        margin-bottom: 8px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        color: $color-black;
        @include tank-font($font-size-16px, $font-weight-medium, $font-family-dm-sans-500);
        @include mobile-and-tablet {
          font-size: $font-size-15px;
        }
      }
    }
  }
  .social-links-block {
    display: flex;
    align-items: center;
    a {
      margin: 0 15px 0 0;
      * {
        color: $color-dark-grey;
      }
    }
  }
  .copyright-text {
    margin-bottom: 27px;
    color: $color-dark-grey;
    @include tank-font($font-size-14px, $font-weight-300, $font-family-dm-sans-normal);
  }
}
