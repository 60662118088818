@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.my-drafts {
  max-width: 53rem;
  .tabs {
    margin-bottom: 4.375rem;
  }
  button.MuiTab-root {
    color: $primary-black;
    min-width: 12.0625rem;
  }
  .MuiTab-wrapper {
    text-transform: none;
    @include tank-font($font-size-20px, $font-weight-medium, $font-family-semibold);
  }
  .ques-draft {
    .MuiIconButton-root {
      padding: 0.3125rem;
    }
  }
}
