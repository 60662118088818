@import '../../../scss/variables';

.table-shimmer {
  .MuiTableContainer-root {
    border: 1px solid $color-light-grey;
    border-bottom: 0;
  }
  .MuiTableBody-root {
    th,
    td {
      border-bottom: 1px solid $color-light-grey;
    }
  }
}
