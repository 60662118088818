@import "../../scss/variables";
@import '../../scss/custom_mixins.scss';

/* Common Header CSS */

$mobileCanvasTransitionDelay: 0.4s;

.tank-header {
  .MuiToolbar-root {
    min-height: auto;
  }
  header,
  .MuiToolbar-root {
    height: 65px;
    @include desktop-and-large-screen {
      height: $header-height;
    }
  }
  header {
    a {
      text-decoration: none !important;
    }
    .nav-item {
      width: auto;
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        color: $color-black;
        line-height: 22px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.tank-header-nav {
      box-shadow: none;
      border-bottom: 1px solid $table-head-grey;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: saturate(180%) blur(20px);
      &.dash {
        background-color: transparent;
        backdrop-filter: none;
        transition: background-color 0.15s ease-in;
        &.sticky-nav {
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: saturate(180%) blur(15px);
        }
      }
    }
  }
  &.dash-header {
    p {
      color: $primary-black;
    }
    .dash-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 0 $desktop-gutter-space;
      &.trial-expired {
        justify-content: end;
      }
    }
    .profile {
      display: flex;
      align-items: center;
      .primary-btn {
        @include button-small;
        padding: 0.6875rem;
      }
      .user-team {
        margin-right: 3.5rem;
        button {
          background-color: transparent;
          border: 2px solid #afa2aa;
          box-shadow: none;
          max-width: 170px;
          span {
            color: $primary-black;
            display: block;
            width: 145px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .user-profile {
        .user-dp {
          margin: 0;
        }
      }
    }
  }
}

.header-nav,
.dashboard-page {
  header {
    margin-left: 0;
    width: 100%;
  }
}


/* Landing Page Header */
.header-nav {
  display: flex;
  justify-content: space-between;
  header {
    border: 0 !important;
    backdrop-filter: unset !important;
    transition: background-color 0.15s ease-in;
    h6 {
      @include mobile-and-tablet {
        display: flex;
        align-items: center;
      }
      a {
        display: inline-block;
        @include mobile-and-tablet {
          height: 30px;
        }
      }
    }
    a {
      color: $color-black;
      font-weight: $font-weight-medium;
    }
    &.MuiAppBar-colorPrimary {
      background-color: transparent;
      box-shadow: none;
      &.sticky-nav {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: saturate(180%) blur(20px) !important;
        // box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.01), 0px 4px 5px 0px rgba(0,0,0,0.11), 0px 1px 10px 0px rgba(0,0,0,0.12);
      }
    }
    .MuiToolbar-root {
      padding: 5px $mobile-gutter-space;
      @include desktop-and-large-screen {
        padding: 0 80px;
      }
      .menu-nav-links {
        @include mobile-and-tablet {
          display: none;
        }
      }
    }
  }
  .mobile-login {
    @include desktop-and-large-screen {
      display: none;
    }
  }
  .mobile-nav-icon {
    &.open {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
  
  .mobile-canvas {
    width: 310px;
    min-height: 100vh;
    position: fixed;
    background: $color-white;
    z-index: 9;
    top: 0;
    right: -400px;
    padding: 80px 30px;
    transition: $mobileCanvasTransitionDelay cubic-bezier(0.645, 0.045, 0.355, 1);
    display: block;
    overflow-y: auto;
    transform: translateX(100%);
    @include tablet {
      width: 50%;
    }
    ul {
      display: block;
      padding: 0;
      li {
        color: $primary-black;
        padding: 8px 0;
      }
      a {
        @include tank-font($font-size-24px, $font-weight-bold, $font-raleway-bold);
      }
    }
    @include desktop-and-large-screen {
      display: none;
    }
    &.open {
      right: 0;
      transform: none;
    }
  }

  .mobile-canvas-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 8;
    transition: $mobileCanvasTransitionDelay cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: hidden;
    opacity: 0;
    margin: 0 -#{$mobile-gutter-space};
    &.open {
      opacity: 1;
      visibility: visible;
    }
    @include desktop-and-large-screen {
      display: none;
    }
  }
}


/* Dashboard Page Header */

body header.MuiAppBar-colorPrimary {
  background-color: $color-white;
}

.tank-header a,
.tank-header .drawer-item span,
.tank-drawer .menu-items a,
.desktop-drawer span,
.tank-header .nav-item button {
  @include tank-font($font-size-16px, $font-weight-600, $font-raleway-semibold);
}
.tank-header {
  &:not(.header-nav) h6 {
    display: flex;
    a {
      display: inline-flex;
      font-size: $font-size-36px;
      font-weight: $font-weight-bold;
      @include tank-font($font-size-36px, $font-weight-bold, $font-family-regular);
    }
  }
  a {
    color: $color-black;
  }
}

.tank-drawer {
  .menu-items a {
    color: $color-white;
  }
}
