@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.collections-container {
  margin: 15px auto 80px;
  padding: 90px 0 0;
  @include desktop-and-large-screen {
    max-width: 1240px;
    padding: 140px 0 0;
  }
  .add-collection {
    position: relative;
    justify-content: center;
    padding: 30px 40px;
    .add-collection-title {
      @include tank-font($font-size-28px, $font-weight-bold, $font-family-bold);
      color: $primary-black;
      line-height: 42px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .collection-item {
    .collection-item-block {
      position: relative;
      color: $color-text-grey;
      border-radius: 10px;
      padding: 18px 25px;
      box-shadow: $box-shadow;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include desktop-and-large-screen {
        padding: 22px 30px;
        transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
        &:hover {
          box-shadow: 0 5px 15px rgba(0,0,0,0.3);
        }
      }
      .collection-color {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;
        border-radius: 10px 0 0 10px;
      }
    }
    a {
      display: block;
      height: auto;
    }
  }
  .details,
  .created {
    text-align: left;
  }
  .created {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mobile-and-tablet {
      display: none;
    }
    .collection-actions {
      margin-top: 10px;
      .MuiSvgIcon-root {
        cursor: pointer;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  .collec-block-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    border-bottom: 1px solid $divider-color;
    padding-bottom: 10px;
  }
  .collection-date {
    text-align: left;
    span {
      @include tank-font($font-size-13px, $font-weight-600, $font-family-semibold);
      color: $color-dark-grey;
    }
    p {
      @include tank-font($font-size-13px, $font-weight-medium, $font-family-regular);
      margin: 0;
    }
  }
  .modify-collec {
    position: absolute;
    right: 8px;
    top: 12px;
    @include desktop-and-large-screen {
      right: 18px;
      top: 18px;
    }
    button {
      padding: 7px;
    }
  }
  .collection-body {
    color: $color-text-grey;
    width: 100%;
    .redirect {
      margin-bottom: 30px;
      &:hover {
        cursor: pointer;
        .collection-title {
          color: $brand-color;
        }
      }
    }
    .creator {
      @include desktop-and-large-screen {
        display: none;
      }
    }
    .queries-count,
    .creator {
      @include mobile-and-tablet {
        p,
        span {
          @include tank-font($font-size-13px, $font-weight-600, $font-family-semibold);
        }
        p {
          margin: 0;
        }
        span {
          color: $color-dark-grey;
        }
      }
    }
  }
  .collection-title {
    margin-bottom: 2px;
    @include tank-font($font-size-15px, $font-weight-600, $font-raleway-semibold);
    transition: color 0.15s ease-in;
    color: $primary-black;
    @include desktop-and-large-screen {
      font-size: $font-size-22px;
      margin-bottom: 7px;
    }
  }
  .collection-title,
  .collection-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $primary-black;
  }
  .collection-desc {
    @include tank-font($font-size-12px, $font-weight-600, $font-raleway-medium);
    margin: 0;
    color: $color-dark-grey;
    @include desktop-and-large-screen {
      font-size: $font-size-15px;
    }
  }
  .collection-author {
    text-align: left;
    padding-bottom: 10px;
    p {
      @include tank-font($font-size-15px, $font-weight-600, $font-family-semibold);
      margin: 0;
    }
  }
  .collec-analytics {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 22px 0 15px;
    .stat {
      display: flex;
      align-items: center;
      &:nth-child(1) {
        padding-right: 8px;
      }
      &:nth-child(2) {
        padding-left: 8px;
      }
      span {
        padding-left: 6px;
        font-size: $font-size-14px;
      }
    }
  }
  .queries-count {
    min-height: 20px;
    p {
      @include tank-font($font-size-14px, $font-weight-normal, $font-raleway-regular);
      margin: 0;
      color: $color-text-grey;
    }
  }
  .add-new-collection {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    .collection-search-container {
      display: flex;
      align-items: center;
      .input-field {
        margin-right: 18px;
      }
      input {
        margin: 0;
        font-family: $font-raleway-regular;
        border-radius: 8px;
      }
      select {
        border: 1px solid $color-grey;
        height: 36px;
        padding: 0 10px;
        font-family: $font-raleway-bold;
        font-weight: bold;
        color: #6A6A6A;
        border-radius: 8px;
      }
    }
  }
  .creator-details-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    .creator-info-name {
      p {
        margin: 0;
        @include tank-font($font-size-14px, $font-weight-normal, $font-raleway-regular);
      }
    }
    .creator-info {
      display: flex;
    }
    .creator-info-dp {
      .user-dp {
        margin-right: 6px;
      }
    }
  }
}

body {
  .edit-menu.collection {
    .MuiPopover-paper {
      box-shadow: 0px 4px 0px -12px rgba(0,0,0,0.01), 0px -1px 10px 1px rgba(0,0,0,0.01), 0px 3px 8px 0px rgba(0,0,0,0.12);
    }
  }
}

.page-title-container {
  margin-bottom: 86px;
  .page-title-text {
    font-size: 32px;
    font-weight: bold;
    font-family: $font-raleway-semibold;
    margin-bottom: 2px;
  }
  .page-description-text {
    font-size: 15px;
    font-weight: 600;
    font-family: $font-raleway-semibold;
    color: #979797;
  }
}
