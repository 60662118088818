@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.verifyEmailPage {
  display: flex;
  align-items: center;
  justify-content: center;
  @include tank-font($font-size-16px, $font-weight-normal, $font-raleway-medium);
  h2 {
    position: relative;
    font-family: $font-raleway-semibold;
    .tank-spinner {
      right: -30px;
      top: 2px;
    }
  }
  .verifyEmail__PageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .verifyEmail__Msg {
    .error-title {
      font-size: $font-size-18px;
      font-family: $font-raleway-semibold;
    }
    .generate-link {
      text-align: center;
      position: relative;
      .tank-spinner {
        top: 54%;
      }
      .tank-btn {
        margin-top: 15px;
        text-align: center;
        .MuiButton-label {
          font-family: $font-raleway-medium;
        }
      }
    }
  }
  .error {
    color: $color-red;
    margin: 18px 0 0;
  }
}
