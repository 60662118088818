@import '../../scss/variables';

body .tank-spinner {
  position: absolute;
  right: 15px;
  &.white {
    color: $color-white;
  }
  &.blue {
    color: $primary-color;
  }
}
