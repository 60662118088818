@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';
@import '../../scss/screens_mixins.scss';

.input-field {
  position: relative;
  .input-label {
    color: $primary-black;
    margin-bottom: 5px;
    @include tank-font($font-size-14px, $font-weight-medium, $font-family-semibold);
    @include desktop-and-large-screen {
      font-size: $font-size-15px;
    }
  }
  .text-field {
    font-size: $font-size-14px;
    padding: 7px 13px;
    border-radius: 4px;
    border: 1px solid $color-grey;
    color: $color-black;
    outline: 0;
    width: 100%;
    height: 36px;
    margin-bottom: 12px;
    background-color: transparent;
    transition: border-color 0.2s ease-in-out;
    @include desktop-and-large-screen {
      margin-bottom: 20px;
    }
    &.description {
      height: 80px;
    }
    &.help-text {
      margin-bottom: 4px;
    }
    &:focus {
      border-color: $grey-dark;
    }
    &::-webkit-input-placeholder {
      font-size: 12px;
    }
    &::-moz-placeholder {
      font-size: 12px;
    }
    &:-ms-input-placeholder {
      font-size: 12px;
    }
    &:-moz-placeholder {
      font-size: 12px;
    }
  }
  .help-text-label {
    color: $color-red;
    @include tank-font($font-size-12px, $font-weight-600, $font-family-semibold);
    margin-bottom: 20px;
  }
  textarea {
    resize: none;
  }
  .input-submit-btn {
    position: absolute;
    right: 0;
    min-width: auto;
    line-height: normal;
    padding: 0;
    height: 36px;
    box-shadow: none;
    width: 40px;
    background-color: $primary-color;
    &:disabled {
      background-color: $color-grey;
    }
    &:hover {
      background-color: $primary-color-hover;
    }
  }
  .password-visibility {
    position: absolute;
    border: 0;
    background: transparent;
    padding: 0;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
