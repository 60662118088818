@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.question-detail-page,
.get-started-detail-page {
  [class*="language-"] {
    padding: 12px;
    background: $code-bg-color;
    display: inline-block;
    border-radius: 3px;
    code {
      padding: 0;
    }
  }
  code {
    padding: 1.5px 4px;
    background: $code-bg-color;
    border-radius: 3px;
  }
  p {
    line-height: 25px;
  }
  @include desktop-and-large-screen {
    margin-top: 140px;
  }
  .ques-detail-container {
    margin-bottom: 160px;
  }
  .ques-container {
    border-bottom: 1px solid $divider-color;
    padding-bottom: 8px;
    .ques-collection-title {
      position: relative;
      padding-left: 12px;
      .collec-border {
        position: absolute;
        left: 0;
        height: 100%;
        width: 5px;
      }
    }
    .ques-title {
      margin-bottom: 15px;
      @include tank-font($font-size-22px, $font-weight-medium, $font-family-medium);
    }
    .ques-description {
      margin-bottom: 45px;
    }
    .author-info h5 {
      color: $color-text-grey;
      @include tank-font($font-size-12px, $font-weight-normal, $font-family-regular);
    }
  }
  .add-answer-btn-wrapper,
  .add-answer-btn {
    width: 100%;
  }
  .answers-container {
    .no-answers {
      margin-top: 35px;
      text-align: center;
      @include tank-font($font-size-30px, $font-weight-medium, $font-family-medium);
    }
  }
  .add-answer-btn {
    position: fixed;
    bottom: 0;
    left: 100%;
    transform: translateX(-100%);
    margin: 0;
    text-align: center;
    padding: 15px 0;
    box-sizing: content-box;
    height: 50px;
    width: calc(100% - #{$drawerWidth});
    .MuiSvgIcon-root {
      margin-right: 7px;
    }
    .MuiFab-label {
      @include tank-font($font-size-14px, $font-weight-bold, $font-family-bold);
    }
  }
  table {
    tbody {
      tr td {
        padding: 14px 10px;
      }
    }
    p {
      line-height: normal;
      margin: 0;
    }
  }
  .query-not-available {
    .modal-content {
      text-align: center;
      max-width: 507px;
      .confirm-delete-msg {
        text-align: center;
        max-width: 360px;
        margin: 0 auto 20px;
      }
    }
  }
}
