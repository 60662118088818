@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

li.MuiListItem-root {
  @include tank-font($font-size-14px, $font-weight-medium, $font-family-semibold);
}

body.fetching-month-data {
  overflow: hidden;
}

.analytics-page {
  margin-bottom: 70px;
  padding-left: 0;
  padding-right: 0;
  .chart-details {
    text-align: left
  }
  .platform-usage-month-selector {
    margin-bottom: 20px;
    .MuiButton-label {
      @include tank-font($font-size-14px, $font-weight-medium, $font-family-semibold);
    }
  }
  .chart-title,
  .chart-no-data-msg {
    margin-bottom: 2px;
    @include tank-font($font-size-18px, $font-weight-medium, $font-family-semibold);
  }
  .chart-description {
    margin: 0;
    color: $primary-black;
    @include tank-font($font-size-13px, $font-weight-medium, $font-family-semibold);
  }
  .chart-no-data-msg {
    text-align: center;
    font-size: $font-size-16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .stats-container {
    margin-bottom: 35px;
    p {
      margin: 0;
      color: $color-white;
    }
    .stat-item {
      border-radius: 10px;
      padding: 24px;
      min-height: 110px;
    }
    .stat-quantity {
      @include tank-font($font-size-28px, $font-weight-bold, $font-family-bold);
      min-height: 40px;
    }
    .stat-label {
      @include tank-font($font-size-16px, $font-weight-medium, $font-family-semibold);
    }
    .total-questions {
      background-color: #4d4cac;
    }
    .total-members {
      background-color: #669cff;
    }
    .perc-verified {
      background-color: #ff808b;
      display: flex;
      align-items: center;
      height: 110px;
      .perc-verify-wrap {
        width: 100%;
      }
      .perc-verified-pie {
        height: 95px;
        width: 100%;
        .echarts-for-react {
          height: 100% !important;
        }
      }
    }
  }
  .graph-item,
  .search-results-no-data {
    padding-top: 20px;
    height: 100%;
    box-shadow: $box-shadow;
    border-radius: 10px;
    .echarts-for-react {
      height: 100% !important;
    }
  }
  .search-res-container {
    height: 500px;
  }
  .search-results-no-data {
    padding: 24px;
    p {
      @include tank-font($font-size-18px, $font-weight-medium, $font-family-semibold);
      margin-bottom: 25px;
    }
    .table-card {
      table {
        display: block;
        thead {
          display: block;
        }
        tr {
          width: 100%;
          display:flex;
        }
        th,
        td {
          flex-basis:100%;
          flex-grow:2;
          display: block;
        }
        tbody {
          height: 315px;
          display: block;
          position: relative;
          width: 100%;
          overflow-y: scroll;
          @include no-scroll-bar;
        }
      }
    }
  }
  .graph-title {
    @include tank-font($font-size-18px, $font-weight-medium, $font-family-semibold);
    margin-bottom: 25px;
  }
  .no-graph-data {
    padding: 0 24px;
    height: 100%;
    position: relative;
    .no-graph-data-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include tank-font($font-size-16px, $font-weight-medium, $font-family-semibold);
    }
  }
  .graph-item {
    position: relative;
  }
  .graph-item-wrapper {
    height: 500px;
    .echarts-for-react {
      height: 100% !important;
    }
    .team-adoption {
      padding: 20px 40px;
    }
  }
  .platform-traffic {
    margin-bottom: 40px;
    // height: 450px;
    margin: 0 0 40px;
    width: 100%;
    .graph-item.platform {
      padding: 20px 40px 0;
      margin-bottom: 40px;
      height: 450px;
      &.no-data {
        height: 300px;
      }
    }
    .graph-item-wrapper {
      height: 450px;
      // padding: 0 0 0 35px;
    }
  }
  .analytics-upgrade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    .analytics-upgrade-asset {
      height: 455px;
      margin-bottom: 45px;
    }
    img {
      @include image-fit;
      object-fit: contain;
    }
    p {
      @include tank-font($font-size-32px, $font-weight-medium, $font-family-medium);
      margin: 0;
    }
  }
  .collection-activity {
    padding: 20px 40px;
  }
}
