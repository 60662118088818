@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.view-question-modal {
  .modal-content {
    min-height: 435px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    @include tank-font($font-size-28px, $font-weight-600, $font-family-semibold);
    color: $primary-black;
  }
  .creator {
    span {
      font-weight: $font-weight-bold;
      font-family: $font-family-bold;
    }
  }
  .answer-entry {
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }
}
