@import './variables';
@import './custom_mixins.scss';
@import './screens_mixins.scss';

html,
body,
#root,
.tank-web {
  // height: 100%;
}

html {
  font-size: 16px;
}

html body {
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html body,
html body textarea {
  background-color: transparent;
  position: relative;
  font-family: $font-family-dm-sans-normal;
  strong,
  strong * {
    font-family: $font-family-bold;
  }
  h1
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: $font-weight-normal;
  }
  button {
    &:focus {
      outline: 0 !important;
    }
  }
}

a {
  text-decoration: none !important;
  color: $darker-blue;
}

p,
pre {
  margin: 0 0 10px;
}

.tank-web {
  background-color: $color-white;
  position: relative;
  &.is-dashboard {
    background-color: $color-white;
  }
  &.is-onboarding {
    display: flex;
  }
}

.section-title {
  margin: 0 auto 25px;
  text-align: center;
  @include tank-font($font-size-35px, $font-weight-bold, $font-family-bold);
  line-height: 1.3;
  @include mobile-and-tablet {
    font-size: $font-size-32px;
    line-height: 44px;
    margin-bottom: 18px;
  }
  &.compact {
    max-width: 790px;
    line-height: 63px;
  }
  &.left {
    text-align: left;
  }
}

.subheading {
  @include tank-font($font-size-16px, $font-weight-600, $font-raleway-semibold);
  line-height: 26px;
  color: $color-dark-grey;
  @include desktop-and-large-screen {
    font-size: $font-size-20px;
    line-height: 30px;
  }
}

.MuiSvgIcon-colorDisabled {
  pointer-events: none !important;
}

.editor-table {
  border: 1px solid;
  border-collapse: collapse;
  td,
  th {
    border: 1px solid;
    padding: 14px 10px;
  }
}

.editor-code {
  white-space: pre-wrap;
}

.editor-quote {
  font-style: italic;
  border-left: 4px solid #78C0A8;
  margin-left: 0;
  padding: 1.2em 30px 1.2em 40px;
  position: relative;
  &:before {
    font-family: Arial;
    content: "\201C";
    color:#78C0A8;
    font-size: 4em;
    position: absolute;
    left: 2px;
    top: -10px;
  }
}

.no-list-style {
  padding-left: 18px;
  list-style: none;
  input {
    pointer-events: none;
  }
}
