.reject-answer-modal {
  .modal-content {
    width: 30%;
  }
  .input-field {
    textarea.description {
      height: 150px;
    }
  }
}
