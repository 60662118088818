/* All custom variables will be placed here.*/

/************* Colors *************/

/* Brand colors */
$brand-color-light: #a1a9fe;
$brand-color-lighter: #afb6ff;
$brand-color-dark: #8b94ef;

/* Primary colors */
$color-teal: #11cdef;
$color-white: #ffffff;
$color-red: #e91e63;
$err-red: #f44336;
$color-black: #000000;
$color-linear-gradient: linear-gradient(60deg, #ec407a, #c2185b);
$brand-color: #1894e0;
$darker-blue: #0a79bd;
$primary-black: #3f434a;
$divider-color: rgba(0, 0, 0, 0.12);
$color-off-white: #f9f9f9;
$color-green: #28a25c;
$green-shade: #79F4CF;
$yellow: #f1c40f;

$color-text-grey: #404448;
$color-light-grey: #f3f3f3;
$color-grey: #ced2d9;
$disabled-btn: $color-grey;
$grey-dark: #8898aa;
$color-dark-grey: #767779;
$divided-grey-secondary: #bdbaba;
$table-head-grey: #e2e2e2;
$light-purple: #f0f3ff;
$color-desc-grey: #929292;
$bg-grey: #FAFAFA;
$grey-shade: #F8F8F8;

$body-bg-color: #dde6e5;
$code-bg-color: #e4e6e8;
$color-beige: #ffe7cd;
$color-beige-dark: #ffd6a8;

$color-dark-orange: #F09150;
$color-light-orange: #ffcdaaeb;

// color options
$color-light-biege: #fbe6ce;
$color-pink: #ffbbbb;

$dark-red: #DC5A4B;
$pretty-peach: #eebacb;

$primary-color: #3c5ffb;
$primary-color-hover: #2c53ff;
$primary-color-light: #6d88ff;

$border-color-light: rgba(0,0,0,.08);

$box-shadow: 0 3px 15px rgba(0,0,0,0.12);
$box-shadow-secondary: 0 1px 0 0 rgba(0,0,0,.05), 0 0 20px 0 rgba(0,0,0,.15);
$box-shadow-primary: 0 12px 14px 0 rgba(29, 38, 100, 0.19), inset 0 1px 0 0 #ffffff;

/* Secondary colors */

/* Other colors */

/************* Font Family *************/

$font-family-regular: 'SFUIDisplay-Regular';
$font-family-medium: 'SFUIDisplay-Medium';
$font-family-semibold: 'SF-UI-Display-Semibold';
$font-family-bold: 'SF-UI-Display-Bold';

$font-raleway-regular: 'Raleway-Regular';
$font-raleway-light: 'Raleway-Light';
$font-raleway-bold: 'Raleway-Bold';
$font-raleway-semibold: 'Raleway-SemiBold';
$font-raleway-extrabold: 'Raleway-ExtraBold';
$font-raleway-medium: 'Raleway-Medium';

$font-family-dm-sans-normal: 'dm-sans-normal';
$font-family-dm-sans-500: 'dm-sans-500';
$font-family-dm-sans-600: 'dm-sans-600';
$font-family-dm-sans-700: 'dm-sans-700';
$font-family-dm-sans-800: 'dm-sans-800';
$font-family-dm-sans-900: 'dm-sans-900';

/************* Font Size *************/

$font-size-8px: 0.5rem;
$font-size-10px: 0.625rem;
$font-size-12px: 0.75rem;
$font-size-13px: 0.8125rem;
$font-size-14px: 0.875rem;
$font-size-15px: 0.938rem;
$font-size-16px: 1rem;
$font-size-17px: 1.063rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-22px: 1.375rem;
$font-size-24px: 1.5rem;
$font-size-25px: 1.5625rem;
$font-size-28px: 1.75rem;
$font-size-30px: 1.875rem;
$font-size-32px: 2rem;
$font-size-35px: 2.188rem;
$font-size-36px: 2.25rem;
$font-size-38px: 2.375rem;
$font-size-40px: 2.5rem;
$font-size-45px: 2.813rem;
$font-size-48px: 3rem;
$font-size-50px: 3.125rem;
$font-size-55px: 3.4375rem;
$font-size-65px: 4.0625rem;
$font-size-72px: 4.5rem;
$font-size-76px: 4.75rem;
$font-size-80px: 5rem;

/************* Font Weight *************/

$font-weight-100: 100;
$font-weight-300: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-600: 600;
$font-weight-bold: bold;

/************* Z-Index *************/

$zindex-1: 1;
$zindex-1000: 1000;
$zindex-1010: 1010;
$zindex-1020: 1020;
$zindex-1030: 1030;
$zindex-1040: 1040;
$zindex-1050: 1050;

/************* Breakpoint Values *************/

/* Extra Large Screen */
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

/* Large Screen */
$screen-lg: 992px;
$screen-lg-min: $screen-lg;
$screen-lg-max: ($screen-xl - 0.1);

/* Tablet screen */
$screen-md: 768px;
$screen-md-min: $screen-md;
$screen-md-max: ($screen-lg - 0.1);

/* Mobile screen */
$screen-sm: 576px;
$screen-sm-min: $screen-sm;
$screen-sm-max: ($screen-md - 0.1);

/* Extra small mobile screen */
$screen-xs: 320px;
$screen-xs-min: $screen-xs;
$screen-xs-max: ($screen-sm - 0.1);

/* Other */
$screen-max-width: 1440px;
$screen-tab-landscape-max: 1024px;
$screen-mac-smaller-resolution: 1280px;

/************* Gutter space *************/

$desktop-gutter-space: 85px;
$mobile-gutter-space: 24px;

$desktop-gutter-space-dashboard: 24px;

$page-layout-margin-top-desktop: 117px;
$page-layout-margin-top-mobile: 60px;

$dashboard-page-margin-top-desktop: 167px;
$dashboard-page-margin-bottom-desktop: 80px;

$dashboard-page-margin-top-mobile: 60px;
$dashboard-page-margin-bottom-mobile: 60px;

/************* Drawer width *************/
$drawerWidth: 270px;

/************* Header height *************/
$header-height: 80px;

$margin-bottom-mobile: 50px;
$margin-bottom-desktop: 140px;

$compact-width: 770px;

$bordered-box-width: 955px;
