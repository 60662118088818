@import "../../scss/variables";
@import "../../scss/custom_mixins.scss";

.section-head {
  margin: 0 -#{$desktop-gutter-space};
  padding-bottom: 4.5rem;
  background-color: #dce3f7 !important;
  // background-color: #fbe8e5;
  .greeting-block {
    margin: 0 0 15px !important;
    padding: 116px $desktop-gutter-space 0;
  }
}
.notifications {
  margin-top: -4.5rem;
  .widgets-container {
    margin-bottom: 75px;
  }
  .notify-container {
    background-color: $color-white;
    padding: 1.375rem 1.5rem;
    // box-shadow: $box-shadow-secondary;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 12px;
    position: relative;
    min-height: 450px;
    max-height: 620px;
    overflow-y: scroll;
    @include no-scroll-bar;
    &.no-items {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .notify {
    position: relative;
    &:before {
      left: 1rem;
      margin-left: -2px;
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      border-right-style: dashed !important;
      border-right: 2px solid #cccccc;
    }
    .notify-block.verify-task {
      cursor: auto;
    }
    .notify-block {
      position: relative;
      &:not(.verify-task):hover {
        background-color: $light-purple;
        cursor: pointer;
        border-radius: 5px;
      }
      &:last-child {
        padding-bottom: 6px;
        margin-bottom: 0;
        .notify-content {
          margin-bottom: 0;
        }
      }
      .notification-icon {
        padding: 7px;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        left: 1rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: 600;
        z-index: 1;
        &.green {
          background-color: #b0eed3;
        }
        &.red {
          background-color: #fdd1da;
        }
        &.blue {
          background-color: #aaedf9;
        }
        img {
          @include image-fit;
          object-fit: contain;
        }
      }
    }
    .notify-content {
      padding: 7px 0;
      margin: 0 auto 30px;
      max-width: 38.875rem;
      margin-left: 3.125rem;
      p:last-child {
        color: $color-text-grey;
        margin: 0;
        @include tank-font(
          $font-size-14px,
          $font-weight-medium,
          $font-family-medium
        );
      }
    }
  }
  .title {
    color: $primary-black;
    @include tank-font($font-size-14px, $font-weight-bold, $font-family-bold);
  }
  .no-catchup-items {
    padding: 55px 0;
    text-align: center;
    p {
      @include tank-font(
        $font-size-20px,
        $font-weight-medium,
        $font-family-semibold
      );
      margin: 0;
    }
    .no-catchup-asset {
      width: 285px;
      margin-bottom: 30px;
      display: inline-flex;
      img {
        @include image-fit;
      }
    }
  }
  .mention-user {
    span {
      font-weight: $font-weight-bold;
      font-family: $font-family-bold;
    }
  }
  .get-started-widget {
    .widget-content {
      height: 127px;
    }
    .entry {
      span {
        transition: color 0.2s ease-in;
      }
      a:hover span {
        color: $darker-blue;
      }
    }
  }
  .space-users-widget {
    .widget-content {
      height: 170px;
    }
    .space-user-dp {
      width: 40px;
      height: 40px;
      img {
        @include image-fit;
        object-fit: contain;
        border-radius: 100%;
      }
    }
  }
  .notification-origin {
    display: inline-flex;
    width: 10px;
    height: 10px;
  }
  .notification-origin-slack {
    .notification-origin {
      width: 16px;
      height: 16px;
    }
  }
}

.retry-login-modal {
  .modal-content {
    max-width: 450px;
    .confirm-delete-msg {
      max-width: 360px;
      margin: 0 0 20px;
      @include tank-font(
        $font-size-18px,
        $font-weight-medium,
        $font-family-medium
      );
    }
  }
}
