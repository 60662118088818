@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.collection-questions {
  margin-bottom: 0;
  .question-dash-entry:nth-of-type(3) {
    margin-top: 60px;
  }
  .collection-info-wrapper {
    margin-bottom: 48px;
    &.read-more-content {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &.not-expanded .collection-copy p {
      display: block;
    }
    .collection-info {
      h1 {
        @include tank-font($font-size-40px, $font-weight-medium, $font-family-bold);
        margin: 00;
        color: $primary-black;
      }
      p {
        @include tank-font($font-size-20px, $font-weight-normal, $font-family-regular);
        line-height: 28px;
        margin: 0;
        color: $primary-black;
      }
    }
    .read-more-btn {
      text-align: center;
      height: 32px;
      button {
        background-color: transparent;
        border: 0;
        transform: rotate(90deg);
        transition: transform 250ms ease;
        &:hover {
          cursor: pointer;
        }
      }
      &.expanded {
        button {
          transform: rotate(-90deg);
        }
      }
    }
    .collection-modify-info {
      .modify-info {
        color: #979797;
        margin: 0;
        @include tank-font($font-size-12px, $font-weight-normal, $font-raleway-regular);
      }
    }
    .collection-copy {
      margin-bottom: 13px;
    }
  }
  .queries-heading {
    margin: 0px 0 25px;
    h1 {
      @include tank-font($font-size-30px, $font-weight-bold, $font-family-bold);
    }
    span {
      padding-left: 25px;
      color: #9A9A9A;
      @include tank-font($font-size-30px, $font-weight-bold, $font-family-regular);
    }
  }
  .collection-queries-container {
    background-color: #FAFAFA;
    margin: 0 -85px;
    padding: 0 85px;
    height: 100vh;
    padding-top: 50px;
  }
}

.no-questions-added {
  margin-top: 75px;
  text-align: center;
  .no-ques-title {
    margin-bottom: 35px;
  }
  .new-ques-btn {
    button {
      width: auto;
    }
  }
}
