@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.choose-admin-modal {
  .modal-content {
    width: 500px;
    .admin-err-msg {
      margin-bottom: 33px;
      @include tank-font($font-size-17px, $font-weight-medium, $font-family-medium);
    }
    .btn-group {
      text-align: center;
      button {
        width: 125px;
      }
    }
  }
}
