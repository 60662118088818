@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.data-error-container {
  &.pos-absolute-center {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  display: flex;
  align-items: center;
  color: $color-red;
  span {
    @include tank-font($font-size-18px, $font-weight-600, $font-family-medium);
    color: $color-red;
    margin-left: 4px;
  }
}
