@import "../../scss/variables.scss";
@import "../../scss/screens_mixins.scss";
@import "../../scss/custom_mixins.scss";

.pricing-section {
  padding-bottom: 80px;
  @include mobile-and-tablet {
    padding-top: 118px;
  }
  @include desktop-and-large-screen {
    padding-bottom: 170px;
  }
  width: 100%;
  .MuiGrid-container {
    width: 100%;
    margin: 0;
  }
  .plan-toggle {
    text-align: center;
    margin-bottom: 50px;
    ul {
      list-style: none;
      display: inline-flex;
      margin: 0;
      padding: 0;
      li {
        padding: 12px 30px;
        font-size: 12px;
        color: $color-black;
        cursor: pointer;
        border: 2px solid $color-red;
        border-radius: 30px;
        &:first-child {
          margin-right: 20px;
        }
        button {
          color: $color-black;
          text-decoration: none;
          border: 0;
          background: none;
          outline: none;
          cursor: pointer;
        }
        &.active {
          background-color: $color-red;
          border-color: $color-red;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
            0 13px 24px -11px rgba(233, 30, 99, 0.6);
          button {
            color: $color-white;
          }
        }
      }
    }
  }
  .row {
    margin: 0;
  }
  .card {
    margin: 0;
  }
  .pricing-card {
    text-align: center;
  }
  .pricing-card:nth-child(2) .card-info {
    background: $color-linear-gradient;
    .plan {
      border-color: hsla(0, 0%, 100%, 0.3);
    }
    button {
      background-color: $color-white !important;
      color: $color-black;
    }
    p,
    span,
    sup {
      color: $color-white;
    }
  }
  .login-btn {
    text-align: right;
    margin-bottom: 80px;
    @include tablet-and-desktop {
      margin-bottom: 100px;
    }
    a {
      border: 2px solid $color-red;
      padding: 12px 30px;
      color: $color-black;
      border-radius: 30px;
      text-decoration: none;
    }
  }
  .pricing-page-width {
    max-width: 1020px;
  }
  .btn-comp {
    max-width: 150px;
    padding: 9px 25px;
    background-color: $color-black;
    &:hover {
      background-color: $color-text-grey;
    }
    span {
      color: $color-white;
      text-transform: none;
      @include tank-font($font-size-16px, $font-weight-600, $font-raleway-semibold);
    }
  }
  .plan-highlight-shadow:before {
    content: "";
    position: absolute;
    width: 90%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.25);
    filter: blur(12px);
  }
  .cycle-switch,
  .plan-switch {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 370px;
    margin: 0 auto;
    @include desktop-and-large-screen {
      margin-bottom: 60px;
    }
    .switch-btn {
      margin: 0;
    }
    .switch-type {
      margin: 0;
      color: $grey-dark;
      transition: color 0.2s ease;
      @include tank-font($font-size-16px, $font-weight-bold, $font-raleway-bold);
      text-align: center;
      @include desktop-and-large-screen {
        font-size: $font-size-14px;
      }
      &.active-plan {
        color: $color-black;
      }
    }
  }
  .cycle-switch {
    flex-wrap: nowrap;
    @include mobile-and-tablet {
      margin-bottom: 50px;
    }
    .switch-type {
      @include mobile-and-tablet {
        line-height: 1.1;
      }
    }
  }
  .plan-switch {
    margin-bottom: 15px;
    justify-content: center;
    @include desktop-and-large-screen {
      display: none;
    }
    .switch-type {
      font-size: $font-size-18px;
    }
  }
  .pricing-plans {
    overflow: visible;
    position: relative;
    &.plan-highlight-shadow:before {
      width: 28%;
      height: 690px;
      top: -9px;
      background: rgba(0, 0, 0, 0.18);
      filter: blur(16px);
      right: 8px;
      left: auto;
      transform: none;
    }
    &.large-res {
      @include mobile-and-tablet {
        display: none;
      }
    }
    .plan-highlight {
      position: absolute;
      width: 85%;
      height: 100%;
      background-color: #7993FF;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .pro-val,
    .pro-val span,
    .pro-val .plan-type,
    .pro-val .plan-amount {
      position: relative;
    }
    .plan-type,
    .plan-amount {
      max-width: 148px;
      margin: 0 auto;
      text-align: left;
    }
    .plan-type {
      margin-bottom: 10px;
      text-transform: uppercase;
      color: $color-desc-grey;
      @include tank-font($font-size-16px, $font-weight-600, $font-raleway-bold);
    }
    .plan-amount {
      margin-bottom: 12px;
      span {
        color: $color-black;
        @include tank-font($font-size-16px, $font-weight-600, $font-raleway-semibold);
        &.amount {
          @include tank-font($font-size-32px, $font-weight-600, $font-raleway-bold);
        }
      }
    }
    .pro-val {
      .plan-type,
      .plan-amount span {
        color: $color-white;
      }
      button {
        background-color: $color-white;
        span {
          color: $color-black;
        }
      }
    }
    thead {
      th {
        padding-bottom: 20px;
      }
      .pro-val .plan-highlight {
        border-radius: 6px 6px 0 0;
      }
    }
    tbody th,
    tbody td,
    .feature-item p {
      @include tank-font($font-size-15px, $font-weight-medium, $font-raleway-medium);
    }
    tbody {
      th,
      td {
        padding: 12px 16px;
      }
      tr:last-child {
        .pro-val .plan-highlight {
          border-radius: 0 0 6px 6px;
          height: 60px;
        }
      }
    }
    &.small-res {
      box-shadow: $box-shadow;
      padding: 23px 24px;
      @include tablet {
        max-width: 484px;
        margin: 0 auto;
      }
      @include desktop-and-large-screen {
        display: none;
      }
      .plan-info {
        text-align: center;
        margin-bottom: 33px;
        .plan-type {
          margin-bottom: 0;
        }
      }
      .plan-item {
        display: none;
      }
      .feature-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        p {
          font-size: $font-size-14px;
          margin: 0;
        }
        .title {
          font-weight: 600;
          font-family: $font-raleway-semibold;
        }
        &:last-child {
          margin: 0;
        }
      }
      &.Basic .plan-item.Basic {
        display: block;
      }
      &.Pro .plan-item.Pro {
        display: block;
      }
      &.Free .plan-item.Free {
        display: block;
      }
    }
  }
}
