@import '../../../scss/variables';
@import '../../../scss/custom_mixins.scss';

.delete-modal {
  .tank-modal.delete {
    text-align: center;
    .confirm-delete-msg {
      width: 65%;
      margin: 0 auto 27px;
      line-height: 30px;
      @include tank-font($font-size-20px, $font-weight-600, $font-family-semibold);
    }
    button {
      &:nth-of-type(1) {
        margin-right: 10px;
      }
      span {
        font-weight: $font-weight-600;
      }
    }
  }
  .modal-content {
    width: 537px;
    text-align: center;
    padding: 30px 33px;
  }
}
