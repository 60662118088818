.get-started-detail-page {
  .ques-detail-container {
    margin: 0;
  }
  .ques-container {
    .ques-description {
      margin: 0;
    }
  }
  .answers-container {
    .answer-entry {
      border: 0;
    }
  }
}
