@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.question-dash {
  padding: 0;
  margin-top: 140px;
  table {
    td {
      a {
        color: $primary-black;
        transition: color 0.1s ease-in;
        @include text-clamp(2);
      }
      &.origin-img {
        img {
          width: 40px;
          height: 30px;
          object-fit: contain;
        }
      }
    }
    .ques-title {
      max-width: 250px;
      &:hover {
        a {
          color: $darker-blue;
        }
      }
    }
    .follow-cell {
      width: 170px;
      .primary-btn {
        min-width: 95px;
        &.unfollow {
          border-color: transparent;
          background-color: #5C7AFF;
          span {
            color: #ffffff;
          }
        }
      }
    }
    .collection-name-cell {
      .collec-border {
        border: 0.0938rem solid;
        position: absolute;
        height: 100%;
        left: 0;
      }
      .collec-name {
        position: relative;
        padding-left: 10px;
      }
      span {
        display: block;
      }
    }
    .creator {
      display: flex;
      align-items: center;
      .user-name {
        display: block;
      }
    }
  }
  .responses {
    position: relative;
  }
  .responses .add-ans {
    position: absolute;
    right: 4.375rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.1875rem;
    &:hover {
      cursor: pointer;
    }
  }
}
