@import '../../scss/variables';

.answer-posts {
  .answer-wrapper {
    display: flex;
    align-items: center;
  }
  .answer-edit-options {
    .tank-spinner {
      position: static;
      // margin-right: 17px;
    }
  }
  .answer {
    max-width: 13.75rem;
    span {
      color: $primary-black;
    }
    * {
      transition: color 0.1s ease-in;
    }
    *:hover {
      color: $darker-blue;
    }
  }
  .tooltip-verification-info {
    margin-left: 6px;
    &.pending,
    &.pending > * {
      color: $color-dark-grey !important;
    }
    &.verified,
    &.verified > * {
      color: $color-green !important;
    }
  }
  td {
    .MuiSvgIcon-root {
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        margin-left: 0.9375rem;
      }
    }
    .origin-img {
      width: 40px;
      height: 30px;
      object-fit: contain;
    }
  }
  .collec-name {
    position: relative;
    padding-left: 10px;
    display: block;
  }
  .collec-border {
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
  }
  .creator {
    display: flex;
    align-items: center;
  }
}
