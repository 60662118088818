@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.tank-header.dash-header {
  .mobile-nav-icon {
    right: 0;
    left: $mobile-gutter-space;
    width: 27px;
    height: 18px;
    span {
      height: 2px;
      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }
  }
  .session-status {
    flex-direction: column;
    align-items: baseline;
    p {
      margin: 0;
    }
  }
  .trial-banner {
    background-color: $yellow;
    text-align: center;
    padding: 4px 0px;
    span {
      @include tank-font($font-size-12px, $font-weight-600, $font-raleway-medium);
      color: $color-black;
    }
  }
}

header.trial-active-banner {
  height: 113px;
}
