@import '../../../scss/variables';

.modal-comp {
  .modal-content {
    position: relative;
    .close-modal-icon {
      position: absolute;
      right: 33px;
      top: 33px;
      cursor: pointer;
    }
  }
  &.full-page-editor {
    .btn-group {
      margin-bottom: 64px;
    }
    .close-modal-icon {
      font-size: 32px;
      top: 54px;
    }
  }
}
