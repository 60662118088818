@import '../../scss/variables';
@import '../../scss/custom_mixins.scss';

.answers-container {
  .answer-entry {
    padding: 15px 20px;
    border-bottom: 1px solid $divider-color;
    // display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answer-block {
    padding-right: 85px;
  }
  .answer-modify {
    display: flex;
    .MuiSvgIcon-root {
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        margin-right: 14px;
      }
    }
  }
  .user-answer {
    font-size: $font-size-16px;
    font-weight: $font-weight-normal;
    color: $color-black;
    margin-bottom: 45px;
  }
  .answer-detail {
    color: $color-text-grey;
    @include tank-font($font-size-12px, $font-weight-normal, $font-family-regular);
    margin-bottom: 8px;
    line-height: normal;
  }
  img {
    max-width: 835px;
  }
  .verification-label {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    .verification-icon {
      &.verified {
        background-color: $color-green;
      }
      &.pending {
        background-color: $color-dark-grey;
      }
      svg {
        width: 20px;
        height: 20px;
        color: $color-white;
      }
      span {
        color: $color-white;
        @include tank-font($font-size-12px, $font-weight-bold, $font-family-semibold);
      }
    }
  }
}
